export const SET_ACTIVE_ROLE = 'seller/SET_ACTIVE_ROLE'

const initialState = {
  data: {}
}

export default (state = initialState, action) => {

  switch (action.type) {

    case SET_ACTIVE_ROLE:
      return {
        ...state,
        data: action.data
      }

    default:
      return state
  }
}

export const setActiveRole = (data) => {
  return dispatch => {
    dispatch({
      type: SET_ACTIVE_ROLE,
      data
    })
  }
}
