import React from 'react'
import { Route, Switch } from 'react-router-dom'
import swal from 'sweetalert'
import Api from '../../../../utils/api'
import { bindActionCreators } from 'redux'
import { push } from "connected-react-router"
import { connect } from 'react-redux'
import NewsTypeMainPage from './main'
import NewsTypeEditPage from './edit'

class NewsType extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      data: []
    };
  }

  componentWillMount = () => {
    this.updateNewsType();
  }

  updateNewsType = () => {
    Api.get('/news-type')
      .then(data => {
        this.setState({ data: data.value || [] });
      });
  }

  onEdit = (data) => {
    let form = new FormData(),
      dataKeys = Object.keys(data.data);

    for(let i=0; i<dataKeys.length; i++) {
      form.append(dataKeys[i], data.data[dataKeys[i]]);
    }

    const query = Api.post('/news-type', form);

    query.then(out => {
        this.props.changePage('/admin/news-type');
        swal({ title: "Успешно", icon: "success", button: "Ок" });
      })
      .catch(() => {
        swal({ title: "Произошла ошибка", icon: "error", button: "Ок" });
      });
  }

  render = () => {
    let location = this.props.location;

    return (
      <div>
        <Switch location={location}>
          <Route path="/admin/news-type/create" component={() => <NewsTypeEditPage onEdit={this.onEdit}/>} />
          <Route path="/admin/news-type" component={() => <NewsTypeMainPage updateNewsType={this.updateNewsType} data={this.state.data}/>} />
        </Switch>
      </div>
    )
  }

}

const mapStateToProps = ({ user, news }) => ({
  user,
  news
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: (page) => push(page)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsType)
