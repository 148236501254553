import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class BuildingCreatePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = props.state;
    this.setData = props.setData;
    this.validateData = props.validateData;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.state = nextProps.state;
  }

  floorsCount(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Этажей в доме</div>
        <div className="value">
          <input className="form-control form-control-sm middle-input" type="number" value={data.floorsCount} onChange={(e) => this.setData('floorsCount', parseInt(e.target.value))} />
        </div>
      </label>
    )
  }

  wallsMaterial(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Материал стен</div>
        <div className="value">
          <select className="custom-select custom-select-sm" value={data.wallsMaterial} onChange={(e) => this.setData('wallsMaterial', e.target.value)}>
            <option value="">Выберите...</option>
            {this.props.general.wallsMaterial.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
          </select>
        </div>
      </label>
    )
  }

  builtYear(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Год постройки</div>
        <div className="value">
          <input className="form-control form-control-sm" type="number" value={data.builtYear} onChange={(e) => this.setData('builtYear', parseInt(e.target.value))} onBlur={(e) => this.validateData('builtYear', /^[0-9]{4}$/, parseInt(e.target.value), 'Год постройки необходимо указывать в формате хххх!')} />
        </div>
      </label>
    )
  }

  notCompletedBuilding() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Недострой</div>
        <div className="value">
          <input type="checkbox" checked={data.isNotCompletedBuilding} onChange={(e) => this.setData('isNotCompletedBuilding', +e.target.checked)} />
        </div>
      </label>
    )
  }

  isGas() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Газ</div>
        <div className="value">
          <input type="checkbox" checked={data.isGas} onChange={(e) => this.setData('isGas', +e.target.checked)} />
        </div>
      </label>
    )
  }

  isPlanDemolition() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Запланирован снос</div>
        <div className="value">
          <input type="checkbox" checked={data.isPlanDemolition} onChange={(e) => this.setData('isPlanDemolition', +e.target.checked)} />
        </div>
      </label>
    )
  }

  yard() {
    let data = this.state.data;
    return (
      <div className="col-4">
        <label>
          <div className="title">Двор</div>
        </label>
        <div className="value">
          <label>
            <input type="checkbox" checked={data.isProtectedPlace} onChange={(e) => this.setData('isProtectedPlace', +e.target.checked)} />
            Закрытая территория
          </label>
        </div>
        <div className="value">
          <label>
            <input type="checkbox" checked={data.isChildrenPlace} onChange={(e) => this.setData('isChildrenPlace', +e.target.checked)} />
            Детская площадка
          </label>
        </div>
        <div className="value">
          <label>
            <input type="checkbox" checked={data.isSportPlace} onChange={(e) => this.setData('isSportPlace', +e.target.checked)} />
            Спортивная площадка
          </label>
        </div>
      </div>
    )
  }

  parking() {
    let data = this.state.data;
    return (
      <div className="col-4">
        <label>
          <div className="title">Парковка</div>
        </label>
        <div className="value">
          <label>
            <input type="checkbox" checked={data.isUndergroundParking} onChange={(e) => this.setData('isUndergroundParking', +e.target.checked)} />
            Подземная парковка
          </label>
        </div>
        <div className="value">
          <label>
            <input type="checkbox" checked={data.isGroundParking} onChange={(e) => this.setData('isGroundParking', +e.target.checked)} />
            Наземная многоуровневая
          </label>
        </div>
        <div className="value">
          <label>
            <input type="checkbox" checked={data.isSlagbaum} onChange={(e) => this.setData('isSlagbaum', +e.target.checked)} />
            За шлакбаумом во дворе
          </label>
        </div>
      </div>
    )
  }

  lift() {
    let data = this.state.data;
    return (
      <div className="col-4">
        <label>
          <div className="title">Лифт</div>
        </label>
        <div className="value">
          <label>
            <input type="checkbox" checked={data.isServiceLift} onChange={(e) => this.setData('isServiceLift', +e.target.checked)} />
            Грузовой
          </label>
        </div>
        <div className="value">
          <label>
            <input type="checkbox" checked={data.isPassengerLift} onChange={(e) => this.setData('isPassengerLift', +e.target.checked)} />
            Пассажирский
          </label>
        </div>
      </div>
    )
  }

  renderParams(type) {

    switch (parseInt(type)) {
      case 1:
        return (
          <div className="area-bg-gray">
            <h5>Здание</h5>
            <div className="input-group">
              <div className="input-group">
                {this.floorsCount("required")}
                {this.wallsMaterial("required")}
                {this.builtYear("required")}
                {this.isGas()}
                {this.isPlanDemolition()}
              </div>
              <div className="input-group">
                {this.yard()}
                {this.parking()}
                {this.lift()}
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="area-bg-gray">
            <h5>Здание</h5>
            <div className="input-group">
              <div className="input-group">
                {this.wallsMaterial("required")}
                {this.floorsCount("required")}
                {this.builtYear("required")}
                {this.isGas()}
                {this.isPlanDemolition()}
              </div>
              <div className="input-group">
                {this.yard()}
                {this.parking()}
                {this.lift()}
              </div>
            </div>
          </div>
        );
    }
  }

  render = () => {
    return (
      <>
        <div className="input-group">
          {this.renderParams(this.state.data.type)}
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ user, general, seller, router }) => ({
  user,
  general,
  seller,
  location: router.location
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: (page) => push(page)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuildingCreatePage)
