import React from 'react'
import { Link } from 'react-router-relative-link'
import { bindActionCreators } from 'redux'
import { setActiveNews } from '../../../modules/news'
import { push } from "connected-react-router"
import { connect } from 'react-redux'
import { Field, FieldType, SortableField, Table } from '../table'
import moment from 'moment'

class NewsMainPage extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      selectedItem: null
    }

    this.tableStruct = [
      SortableField(FieldType.NONE, 'newsItem', 'Новости'),
    ];

  }

  selectedItem = (id) => {
    const selectedItem = this.props.data.find(item => item.id === id);
    if (selectedItem) { this.setState({ selectedItem }); }
  }

  componentDidMount() {
    if (this.props.data.length > 0) {
      const selectedItem = this.props.data[0]
      this.setState({ selectedItem });
    }
  }

  formatDate = (date, epmtyFill = '') => {
    if(!date) {
      return epmtyFill;
    }

    return moment(date).format('DD.MM.YYYY  HH:mm');
  }

  render() {
    let data = this.props.data || [];

    data = data.map(item => {
      return ({
        ...item,
        newsItem: (<div onClick={() => this.selectedItem(item.id)} style={{cursor: 'pointer'}}>
          <p style={{margin: 0}}>{item.title}</p>
          <p style={{margin: 0}}>{this.formatDate(item.creationDate)}</p>
        </div>),
      })
    });

    return (
      <div className="row" style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }}>
        <div className="w-25" style={{width: '25%', overflow: 'hidden'}}>
          <Table data={data} struct={this.tableStruct}/>
        </div>
        <div className="w-75" style={{width: '74%'}}>
          <div className="row">
            {this.props.user.news_create ? (<Link to="./create"className="btn btn-sm btn-info">+ Добавить новость</Link>) : ''}&nbsp;
            {this.state.selectedItem && this.props.user.news_edit ?
            (<Link to="./edit" onClick={() => this.props.setActiveNews(this.state.selectedItem)} className="btn btn-sm btn-info">Редактировать новость</Link>) : ''}
          </div>
          {this.state.selectedItem && (
            <div>
              <p style={{margin: 0}}>{this.formatDate(this.state.selectedItem.creationDate)}</p>
              <h4>{this.state.selectedItem.title}</h4>
              <div style={{margin: 0}} dangerouslySetInnerHTML={{ __html: this.state.selectedItem.news }}></div>
            </div>
          )}
        </div>
      </div>
    )
  }

}

const mapStateToProps = ({ user, general, router }) => ({
  user,
  general,
  location: router.location
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setActiveNews,
      changePage: (page) => push(page)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsMainPage)
