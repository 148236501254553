import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class Administrator extends React.Component {
  constructor (props) {
    super(props);

    this.state = props.state;
    this.setData = props.setData;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.state = nextProps.state;
  }



  render = () => {

    let data = this.state.data;
    const user = this.props.user;

    return (
      <>
        {
          user.isAdmin &&
          <div className="input-group btn-group">
            <label>
              <div className="title">Объеденить с объектом id:</div>
              <div className="value">
                <input type="text" className="form-control form-control-sm" onChange={(e) => this.setData('combineId', e.target.value)} value={data.combineId}/>
              </div>
            </label>
          </div>
        }
        {
          user.isAdmin &&
          <label>
            <div className="title">Промодерировано</div>
            <div className="value">
              <select className="custom-select custom-select-sm" value={data.isChecked} onChange={(e) => this.setData('isChecked', parseInt(e.target.value))}>
                <option value={1} key={1}>Да</option>
                <option value={0} key={2}>Нет</option>
              </select>
            </div>
          </label>
        }
        {
          user.isAdmin &&
          <label>
            <div className="title">Комментарий модератора</div>
            <div className="value">
              <textarea className="form-control form-control-sm" onChange={(e) => this.setData('revisionComment', e.target.value)} value={data.revisionComment}/>
            </div>
          </label>
        }
      </>
    )
  }
}

const mapStateToProps = ({ user, general, seller, router }) => ({
  user,
  general,
  seller,
  location: router.location
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: (page) => push(page)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Administrator)
