import React, { Component } from 'react'
import { CountInfoCard, NotesList, DealsList } from './components'
import Api from '../../../utils/api'
import Buyers from '../buyers/index'

export default class Main extends Component {
  constructor(props) {
    super(props)

    this.state = {
      countCardList: []
    }
  }

  componentDidMount() {
    Promise.all([
      Api.get(`/sellers/stat`),
      Api.get(`/buyers/stat`),
      Api.get(`/deals/stat`),
      Api.get(`/deals/statAll`)
    ]).then(([sellers, buyers, deals, dealAll]) => {
      this.setCard(sellers.all, 'объектов всего', sellers.currentMonth, 5, sellers.currentMonth >= 5 ? 'green' : 'red')
      this.setCard(buyers.all, 'покупателей всего', buyers.currentMonth, 10, buyers.currentMonth >= 10 ? 'green' : 'red')
      this.setCard(deals.all, 'активных задатка', deals.currentMonth, 3, deals.currentMonth >= 3 ? 'green' : 'red')
      this.setCard(dealAll.all, 'сделок всего', dealAll.currentMonth, 3, dealAll.currentMonth >= 3 ? 'green' : 'red')
      this.forceUpdate()
    }).catch(console.log)
  }

  setCard = (titleNumber, title, count, maxCount, bgColor) => {
    this.state.countCardList.push({
      title,
      titleNumber,
      count,
      maxCount,
      bgColor
    })
  }

  render() {
    return (
      <div className="main">
        <div className="row main__count-info-card-list">
          {this.state.countCardList.map((item, index) => (
            <CountInfoCard
              className="main__count-info-card"
              key={index}
              data={item}
            />
          ))}
        </div>
        <div className="row main__content">
          <DealsList className="main__deals main__content-left" />
          <NotesList className="main__content-right" />
        </div>
      </div>
    )
  }
}
