import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import swal from 'sweetalert'
import { Link } from 'react-router-relative-link'

class NewsTypeEditPage extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      data: {
        newsType: ''
      }
    };
  }

  updateData = () => {
    // let activeRealtor = this.props.news.data;

    this.setState({
      data: {
        ...this.state.data,
        // ...activeRealtor
      }
    });
  }

  componentDidMount () {
    this.updateData();
  }

  save = (e) => {
    e.preventDefault();
    let data = Object.assign({}, this.state.data);

    if (!!data.newsType) {
      if(this.props.onEdit) {
        if (this.props.isEdit) {
          this.props.onEdit({ data }, true, this.props.news.data.id);
        } else {
          this.props.onEdit({ data });
        }
      }
    } else {
      swal({ title: "Заполните все поля", icon: "error", button: "Ок" });
    }
  }

  setData = (name, value) => {
    let state = Object.assign({}, this.state);

    state.data[name] = value;
    this.setState(state);
  }

  render = () => {
    const data = this.state.data;

    return (
      <form className="row" onSubmit={this.save}>
        <div className="col-12">
          <label>
            <div className="title required">Название раздела</div>
            <div className="value">
              <input style={{ width: '50%' }} className="form-control form-control-sm" type="text" value={data.newsType} onChange={(e) => this.setData('newsType', e.target.value)}/>
            </div>
          </label>

          <br/>

          <div className="input-group btn-group">
            <button className="btn btn-sm btn-success">Сохранить</button>
            <Link to=".." className="btn btn-sm btn-danger">Отменить</Link>
          </div>
        </div>
      </form>
    )
  }
}

const mapStateToProps = ({ user, news, general, router }) => ({
  user,
  news,
  general,
  location: router.location
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: (page) => push(page)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsTypeEditPage)
