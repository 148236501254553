import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Select from 'react-select';

class Location extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state,
      selectedOption: null
    };
    this.setData = this.props.setData;
    this.district = this.props.general.district.map(item => ({ label: item.name, value: item.id.toString() }));
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const district = nextProps.state.data.district;
    this.setState({
      ...nextProps.state,
      selectedOption: !!district ? this.district.find(item => item.value === district.toString()) : null
    });
  }

  selectAddressSearchBox = (data) => {
    this.state.data.houseNumber = data.data.house != null ? parseInt(data.data.house) : '';
    this.state.data.houseLetter = data.data.house != null ? data.data.house.replace(/[0-9]/ig, "") : '';
    this.state.data.flatNumber = this.props.state.data.flatNumber ? this.props.state.data.flatNumber : '';
    this.state.data.houseCorpse = data.data.block != null ? data.data.block : '';
    this.state.data.street = data.data.street != null ? data.data.street : '';
    this.state.data.metro = data.data.metro != null ? data.data.metro : '';
    this.state.data.address = data.data.city_with_type + ', ' + data.data.street_with_type + ', ' + (data.data.house_type ? data.data.house_type : '') + ' ' + (data.data.house ? data.data.house : '');
    this.setData('address', data.data.city_with_type + ', ' + data.data.street_with_type + ', ' + (data.data.house_type ? data.data.house_type : '') + ' ' + (data.data.house ? data.data.house : ''));
    this.setState({
      addressSearchBox: {
        show: false,
        selectedId: data.data.kladr_id
      },
      data: {
        ...this.state.data,
        //address: data.value
      }
    });
  }

  address(required, text = "Адрес") {
    let data = this.state.data;
    const addressSearchBox = this.state.addressSearchBox;
    //data.address = 'г ' + data.city + ' ' + data.street + ' ' + data.houseNumber + ' ' + data.houseLetter + ' ' + data.flatNumber;
    return (
      <label>
        <div className={`title ${required}`}>{text}</div>
        <div className="value">
          <input className="form-control form-control-sm input-address" type="text" value={data.address} onChange={(e) => this.setData('address', e.target.value)} />
          {addressSearchBox.show && addressSearchBox.data &&
            <ul className="searchingBox">
              {
                addressSearchBox.data.map(item =>
                  <li key={item.data.kladr_id} onClick={() => this.selectAddressSearchBox(item)}>{item.data.city_with_type + ', ' + item.data.street_with_type + ', ' + (item.data.house_type ? item.data.house_type : '') + ' ' + (item.data.house ? item.data.house : '')}</li>
                )
              }
            </ul>
          }
        </div>
      </label>
    )
  }

  city(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Город</div>
        <div className="value">
          {data.city}
        </div>
      </label>
    )
  }

  location(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Расположение</div>
        <div className="value">
          <select className="custom-select custom-select-sm" value={data.location} onChange={(e) => this.setData('location', parseInt(e.target.value))}>
            <option value="">Выберите...</option>
            {this.props.general.location.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
          </select>
        </div>
      </label>
    )
  }

  area(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Район</div>
        <div className="value">
          <Select
            onChange={(e) => { this.setData('district', e.value); }}
            options={this.district}
            isSearchable={true}
            className={'w-25'}
            value={this.state.selectedOption}
            name="district"
            placeholder="Выберите..." />
        </div>
      </label>
    )
  }

  street(required, text = "Улица") {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>{text}</div>
        <div className="value">
          {data.street}
        </div>
      </label>
    )
  }

  cadastral(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Кадастровый номер</div>
        <div className="value">
          <input className="form-control form-control-sm" onChange={(e) => this.setData('cadastral', e.target.value)} type="text" value={data.cadastral} />
        </div>
      </label>
    )
  }

  metro(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Метро</div>
        <div className="value">
          <input className="form-control form-control-sm" onChange={(e) => this.setData('metro', e.target.value)} type="text" value={data.metro} />
        </div>
      </label>
    )
  }

  houseNumber(required, text = "Дом") {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>{text}</div>
        <div className="value">
          {data.houseNumber}
        </div>
      </label>
    )
  }

  houseLetter() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Буква</div>
        <div className="value">
          {data.houseLetter}
        </div>
      </label>
    )
  }

  houseCorpse() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Корпус</div>
        <div className="value">
          {data.houseCorpse}
        </div>
      </label>
    )
  }

  flatNumber(required) {
    let data = this.state.data;
    const user = this.props.user;
    return (
      <>
        {(data.realtor === user.id || !this.props.isEdit) &&
          <label>
            <div className={`title ${required}`}>Квартира</div>
            <div className="value">
              <input className="form-control form-control-sm small-input" type="text" value={data.flatNumber} onChange={(e) => this.setData('flatNumber', e.target.value)} />
            </div>
          </label>
        }
      </>
    )
  }

  landNumber() {
    let data = this.state.data;
    const user = this.props.user;
    return (
      (data.realtor === user.id || !this.props.isEdit) && (
        <label>
          <div className={"title" + (!data.isStudio ? " required" : "")}>Номер участка</div>
          <div className="value">
            <input className="form-control form-control-sm small-input" type="number" value={data.landNumber} onChange={(e) => this.setData('landNumber', parseInt(e.target.value) || 0)} />
          </div>
        </label>
      )
    )
  }

  gardenCommunity() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">В садовом обществе</div>
        <div className="value">
          <input type="checkbox" checked={data.isGardenCommunity} onChange={(e) => this.setData('isGardenCommunity', +e.target.checked)} />
        </div>
      </label>
    )
  }

  inCity() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">В городе</div>
        <div className="value">
          <input type="checkbox" checked={data.isInCity} onChange={(e) => this.setData('isInCity', +e.target.checked)} />
        </div>
      </label>
    )
  }

  renderParams(type) {

    switch (parseInt(type)) {
      case 1:
        return (
          <>
            <div className="input-group">
              {this.address("required")}
              {this.area("required")}
              {this.metro()}
            </div>
            <div className="input-group">
              {/*this.city("required")*/}
              {/*this.street("required")*/}
              {/*this.houseNumber("required")*/}
              {/*this.houseLetter()*/}
              {/*this.houseCorpse()*/}
            </div>
            <div className="input-group">
              {this.flatNumber()}
              {this.cadastral()}
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="input-group">
              {this.city("required")}
              {this.area("required")}
              {this.street("required")}
              {this.metro()}
            </div>
            <div className="input-group">
              {this.houseNumber("required")}
              {this.houseLetter()}
              {this.houseCorpse()}
              {this.flatNumber("required")}
              {this.cadastral()}
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="input-group">
              {this.city("required")}
              {/*{ this.location() }*/}
              {/*{this.gardenCommunity()}*/}
              {/*{this.inCity()}*/}
              {this.cadastral("required")}
            </div>
            <div className="input-group">
              {this.area("required")}
              {this.street("required")}
              {this.houseNumber("required")}
              {this.houseLetter()}
              {/*{this.houseCorpse()}*/}
              {/*{this.flatNumber()}*/}
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="input-group">
              {this.city("required")}
              {this.area("required")}
              {this.metro()}
              {this.cadastral()}
            </div>
            <div className="input-group">
              {this.location("required")}
              {this.street("required")}
              {this.houseNumber("required")}
              {this.houseLetter()}
              {this.houseCorpse()}
              {/*{this.flatNumber()}*/}
            </div>
          </>
        );
      case 5:
        return (
          <>
            <div className="input-group">
              {this.city("required")}
              {this.area("required")}
              {this.metro()}
              {this.cadastral()}
            </div>
            <div className="input-group">
              {this.location("required")}
              {this.street("required", "Вблизи улицы")}
              {this.houseNumber("required", "Номер")}
              {this.houseLetter()}
            </div>
          </>
        )
      case 6:
        return (
          <>
            <div className="input-group">
              {this.city("required")}
              {this.cadastral("required")}
            </div>
            <div className="input-group">
              {this.area("required")}
              {this.street("required")}
              {this.landNumber()}
            </div>
          </>
        );
    }

  }



  render = () => {

    return (
      <>
        {this.renderParams(this.state.data.type)}
      </>
    )
  }
}

const mapStateToProps = ({ user, general, seller, router }) => ({
  user,
  general,
  seller,
  location: router.location
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: (page) => push(page)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Location)
