import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { push } from "connected-react-router"
import { connect } from 'react-redux'
import Users from './users'
import Agency from './agents'
import NewsType from './newsType'
import Roles from './role'


class Admin extends React.Component {

  constructor (props) {
    super(props);
  }

  isActiveTab = (path = '') => {
    const location = this.props.location;
    let pathname = location.pathname.replace('/', '');
    let isActive = false;

    if(!(path instanceof Array)) {
      path = [path];
    }

    for(let i=0; i<path.length; i++) {
      isActive = pathname.length === path[i].length && pathname.indexOf(path[i]) > -1;

      if(isActive) {
        break;
      }
    }

    if(isActive) {
      return 'list-group-item active';
    }
    else {
      return 'list-group-item';
    }
  };

  render = () => {
    let location = this.props.location;

    return (
      <div className="container-fluid">
        <div className="row">
          <h4>Администрирование</h4>
        </div>
        <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{width: '20%'}}>
            <ul className="list-group" style={{ padding: 0 }}>
              <Link className={this.isActiveTab('admin/users')} style={{ width: '100%', display: 'block' }} to="/admin/users">Пользователи</Link>
              <Link className={this.isActiveTab('admin/agency')} style={{ width: '100%', display: 'block' }} to="/admin/agency">Агенства</Link>
              <Link className={this.isActiveTab('admin/news-type')} style={{ width: '100%', display: 'block' }} to="/admin/news-type">Новости и база знаний</Link>
              <Link className={this.isActiveTab('admin/role')} style={{ width: '100%', display: 'block' }} to="/admin/role">Контроль прав доступа</Link>
            </ul>
          </div>
          <div className="col-sm-10" style={{width: '75%'}}>
            <Switch location={location}>
              <Route path="/admin/users" component={() => <Users/>} />
              <Route path="/admin/agency" component={() => <Agency />} />
              <Route path="/admin/news-type" component={() => <NewsType />} />
              <Route path="/admin/role" component={() => <Roles />} />
            </Switch>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = ({ user, news, router }) => ({
  user,
  news,
  location: router.location
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: (page) => push(page)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin)
