import React from 'react'
import { Link } from 'react-router-relative-link'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Api from '../../../utils/api'
import { unsetActiveAd } from '../../../modules/user'
import { setActiveSeller, setSellerFilter } from '../../../modules/seller'
import { Table, Field, SortableField, FieldType } from '../table'
import swal from 'sweetalert'
import ImageGallery from 'react-image-gallery'
import moment from 'moment'
import FullInfo from './FullInfo'
import OfferWindow from './OfferWindow'
import "react-image-gallery/styles/css/image-gallery.css";
import { Modal } from 'react-bootstrap'
import DealsCreatePage from '../deals/create'

const mode = {
    MY: 'MY',
    ALL: 'ALL',
    AGENCY: 'AGENCY'
};

class SellersMainPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            dataCount: 0,
            myCount: 0,
            agencyCount: 0,
            allCount: 0,
            filter: {
                id: '',
                type: 0,
                rooms: -1,
                priceFrom: '0',
                priceTo: '0',
                priceM2From: '0',
                priceM2To: '0',
                district: 0,
                floor: '',
                squareFrom: '0',
                squareTo: '0',
                status: '1',
                page: 1,
                pageSize: 10,
                houseNumber: "",
                city: ""
            },
            layoutWindow: {
                id: 0,
                isShow: false,
                type: 'layout'
            },
            tableTooltip: {
                id: 0,
                show: false,
                x: null,
                y: null,
                html: ''
            },
            imageGalleryArray: [],
            sellerFullInfo: null,
            deal: null,
            showOfferWindow: false,
            addressSearchBox: {
                show: false,
                selectedId: 0
            },
            mode: mode.MY,
            showResetFilter: true,
            loading: false
        };

        this.tableStructAll = [Field(FieldType.NONE, 'isSelected', ''),
        SortableField(FieldType.INTEGER, 'id', '№'),
        SortableField(FieldType.NONE, 'dates', <>Дата создания,<br />редактирования,<br />удаления</>),
        SortableField(FieldType.NONE, 'object', <>Вид,<br />Тип,<br />Статус</>),
        SortableField(FieldType.NONE, 'costHTML', <>Стоимость<br />Цена за м²</>),
        SortableField(FieldType.NONE, 'address', <>Город,<br />Район,<br />Адрес</>),
        SortableField(FieldType.NONE, 'floorHTML', <>Этаж,<br />Комн,<br />Площадь кв.,<br />Площадь уч.,<br />Материал</>),
        SortableField(FieldType.STRING, 'squareHTML', 'Год постройки'),
        SortableField(FieldType.NONE, 'layout', 'Фото/план'),
        SortableField(FieldType.STRING, 'repairs', 'Ремонт'),
        SortableField(FieldType.STRING, 'realtorName', 'Риэлтор'),
        SortableField(FieldType.NONE, 'info', 'Комментарий'),
        Field(FieldType.BUTTON, 'fullInfo', <i class="fa fa-info"></i>),
        Field(FieldType.BUTTON, 'deal', <i class="fa fa-comments-dollar"></i>),
        Field(FieldType.BUTTON, 'edit', <i class="fas fa-pencil-alt" />),
        Field(FieldType.BUTTON, '!deleted', <i class="fas fa-trash-alt" />)
        ];

        if (this.props.user.isAdmin) {
            this.tableStructAll.push(Field(FieldType.BUTTON, 'isChecked', 'На модерацию'));
        }

        this.tableStructMy = [Field(FieldType.NONE, 'isSelected', ''),
        SortableField(FieldType.INTEGER, 'id', '№'),
        SortableField(FieldType.NONE, 'dates', <>Дата создания,<br />редактирования,<br />удаления</>),
        SortableField(FieldType.NONE, 'object', <>Вид,<br />Тип,<br />Статус</>),
        SortableField(FieldType.NONE, 'costHTML', <>Стоимость<br />Цена за м²</>),
        SortableField(FieldType.NONE, 'address', <>Город,<br />Район,<br />Адрес</>),
        SortableField(FieldType.NONE, 'floorHTML', <>Этаж,<br />Комн,<br />Площадь кв.,<br />Площадь уч.,<br />Материал</>),
        SortableField(FieldType.STRING, 'squareHTML', 'Год постройки'),
        SortableField(FieldType.NONE, 'layout', 'Фото/план'),
        SortableField(FieldType.STRING, 'repairs', 'Ремонт'),
        SortableField(FieldType.STRING, 'name', 'Клиент'),
        SortableField(FieldType.NONE, 'info', 'Комментарий'),
        Field(FieldType.BUTTON, 'fullInfo', <i class="fa fa-info"></i>),
        Field(FieldType.BUTTON, 'deal', <i class="fa fa-comments-dollar"></i>),
        Field(FieldType.BUTTON, 'edit', <i class="fas fa-pencil-alt" />),
        Field(FieldType.BUTTON, '!deleted', <i class="fas fa-trash-alt" />)
        ];
        this.tableStructAgency = [Field(FieldType.NONE, 'isSelected', ''),
        SortableField(FieldType.INTEGER, 'id', '№'),
        SortableField(FieldType.NONE, 'dates', <>Дата создания,<br />редактирования,<br />удаления</>),
        SortableField(FieldType.NONE, 'object', <>Вид,<br />Тип,<br />Статус</>),
        SortableField(FieldType.NONE, 'costHTML', <>Стоимость<br />Цена за м²</>),
        SortableField(FieldType.NONE, 'address', <>Город,<br />Район,<br />Адрес</>),
        SortableField(FieldType.NONE, 'floorHTML', <>Этаж,<br />Комн,<br />Площадь кв.,<br />Площадь уч.,<br />Материал</>),
        SortableField(FieldType.STRING, 'squareHTML', 'Год постройки'),
        SortableField(FieldType.NONE, 'layout', 'Фото/план'),
        SortableField(FieldType.STRING, 'repairs', 'Ремонт'),
        SortableField(FieldType.STRING, 'realtorName', 'Риэлтор'),
        SortableField(FieldType.NONE, 'info', 'Комментарий'),
        Field(FieldType.BUTTON, 'fullInfo', <i class="fa fa-info"></i>),
        Field(FieldType.BUTTON, 'deal', <i class="fa fa-comments-dollar"></i>),
        Field(FieldType.BUTTON, 'edit', <i class="fas fa-pencil-alt" />),
        Field(FieldType.BUTTON, '!deleted', <i class="fas fa-trash-alt" />)
        ];
        this.tableStructModerate = [...this.tableStructMy];
        this.tableStructModerate[10] = SortableField(FieldType.STRING, 'realtorName', 'Риэлтор');

        this.tableStructDeleted = [
            ...this.tableStructMy.slice(0, 10),
            SortableField(FieldType.STRING, 'realtorName', 'Риэлтор'),
            SortableField(FieldType.STRING, 'deleteReason', 'Причина удаления'),
            SortableField(FieldType.NONE, 'info', 'Комментарий'),
            Field(FieldType.BUTTON, 'fullInfo', <i class="fa fa-info"></i>),
            Field(FieldType.BUTTON, 'deal', <i class="fa fa-comments-dollar"></i>),
            Field(FieldType.BUTTON, 'edit', <i class="fas fa-pencil-alt" />),
            Field(FieldType.BUTTON, 'remove', <i class="fas fa-trash-restore" />)
        ];
    }

    componentDidMount() {
        this.updateSellers({ agencyId: this.props.user.agencyId });
    }

    updateSellers = (filters) => {
        this.setState({ loading: true });
        let apiPath = '/sellers?';
        for (let prop in filters) {
            if (filters[prop]) {
                apiPath += prop + '=' + filters[prop] + '&';
            }
        }
        Api.get(apiPath)
            .then(data => {
                this.setState({
                    data: data.value || [],
                    dataCount: data.count || 0,
                    myCount: data.myCount || 0,
                    agencyCount: data.agencyCount || 0,
                    allCount: data.allCount || 0,
                    loading: false
                });
            });
    }

    tableHandler = (action, value) => {
        const user = this.props.user;
        let seller = Object.assign({}, this.state.data.find(item => item.id === value));

        switch (action) {
            case 'edit':
                if (seller) {
                    this.props.unsetActiveAd();
                    this.props.setActiveSeller(seller);
                    this.props.changePage('/sellers/edit');
                }

                break;

            case 'deleted':
                if (seller) {
                    swal({ title: "Вы уверены?", icon: "warning", buttons: ["Нет", "Да"] })
                        .then((ok) => {
                            if (ok) {
                                Api.delete('sellers/' + value)
                                    .then(() => {
                                        this.updateSellers();
                                        swal({ title: "Успешно", icon: "success", button: "Ок" });
                                    })
                                    .catch(err => {
                                        swal({ title: "Произошла ошибка", icon: "error", button: "Ок" });
                                    });
                            }
                        });
                }

                break;

            case 'remove':
                if (user.isAdmin) {
                    swal({ title: "Вы уверены?", icon: "warning", buttons: ["Нет", "Да"] })
                        .then((ok) => {
                            if (ok) {
                                Api.delete('sellers/' + value + '?completely=true')
                                    .then(() => {
                                        this.updateSellers();
                                        swal({ title: "Успешно", icon: "success", button: "Ок" });
                                    })
                                    .catch(err => {
                                        swal({ title: "Произошла ошибка", icon: "error", button: "Ок" });
                                    });
                            }
                        });
                }
                break;

            case 'isChecked':
                if (seller) {
                    swal({ title: "Вы уверены?", icon: "warning", buttons: ["Нет", "Да"] })
                        .then((ok) => {
                            if (ok) {
                                Api.put('sellers/cancel/' + seller.id)
                                    .then(() => {
                                        this.updateSellers();
                                        swal({ title: "Успешно", icon: "success", button: "Ок" });
                                    })
                                    .catch(err => {
                                        swal({ title: "Произошла ошибка", icon: "error", button: "Ок" });
                                    });
                            }
                        });
                }

                break;

            case 'fullInfo':
                if (seller) {
                    seller.floor = seller.floor + '/' + seller.floorsCount;
                    seller.square = seller.square + ' м2';
                    seller.repairs = this.getRepairType(seller);
                    seller.layout = <span>{seller.photosCount && <span className="link" onClick={() => this.showLayoutWindow(seller.id, 'photos')}>{seller.photosCount}</span>} / {seller.layoutsCount && <span className="link" onClick={() => this.showLayoutWindow(seller.id, 'layout')}>{seller.layoutsCount}</span>}</span>;
                    seller.address = <>р-н. {this.getDistrict(seller)}, {seller.street + ', ' + seller.houseNumber}</>;

                    this.setState({ sellerFullInfo: seller });
                }
                break;

            case 'deal':
                if (seller) {
                    this.setState({ deal: seller.id });
                }

            default:
                break;
        }
    }

    rowHighlighter = (data) => {
        let mode = this.getMode();

        if (mode !== mode.ALL) {
            return null
        }
        else if (data.realtor === this.props.user.id) {
            return this.props.rowHighlight[0];
        }
    }

    getMode = () => {
        return this.state.mode;
    }

    getRepairType = (data) => {
        let type = this.props.general.repairType.find(item => item.id === data.repairs)

        if (type) {
            return type.name;
        }
        else {
            return null;
        }
    }

    getDistrict = (data) => {
        let type = this.props.general.district.find(item => item.id === data.district)

        if (type) {
            return type.name;
        }
        else {
            return null;
        }
    }

    setFilter = (name, value) => {
        this.setState({
            filter: { ...this.state.filter, [name]: value }
        });
        this.updateSellers({ ...this.state.filter, [name]: value, mode: this.state.mode, agencyId: this.props.user.agencyId });
    }

    setMode = (data) => {
        this.setState(data);
        this.updateSellers({ ...this.state.filter, ...data, agencyId: this.props.user.agencyId });
    }

    getFilteredData = (data) => {
        const filterData = this.state.filter;

        let filter = {
            id: +filterData.id,
            type: +filterData.type,
            status: +filterData.status,
            street: !filterData.street ? '' : filterData.street,
            rooms: +filterData.rooms,
            priceFrom: parseFloat(filterData.priceFrom),
            priceTo: parseFloat(filterData.priceTo),
            priceM2From: parseFloat(filterData.priceM2From),
            realtor: parseFloat(filterData.realtor),
            priceM2To: parseFloat(filterData.priceM2To),
            district: +filterData.district,
            floorFrom: +filterData.floorFrom,
            floorTo: +filterData.floorTo,
            squareFrom: parseFloat(filterData.squareFrom),
            squareTo: parseFloat(filterData.squareTo)
        };


        // if(filter.id > 0) {
        //     data = data.filter(item => item.id === filter.id);
        // }
        //
        // if(filter.type > 0) {
        //     data = data.filter(item => item.type === filter.type);
        // }
        //
        // if (filter.street && filter.street.length > 0) {
        //     data = data.filter(item => item.street.indexOf(filter.street) >= 0);
        // }
        //
        // if(filter.status > 0) {
        //     data = data.filter(item => item.status === filter.status);
        // }
        //
        // if(filter.rooms >= 0) {
        //     data = data.filter(item => (filter.rooms === 0 && item.isStudio && item.rooms === 0) || item.rooms === filter.rooms);
        // }
        //
        // if(filter.priceFrom > 0) {
        //     data = data.filter(item => item.cost >= filter.priceFrom);
        // }
        //
        // if(filter.priceTo > 0) {
        //     data = data.filter(item => item.cost <= filter.priceTo);
        // }
        //
        // if(filter.priceM2From > 0) {
        //     data = data.filter(item => item.priceM2 >= filter.priceM2From);
        // }
        //
        // if(filter.realtor > 0) {
        //     data = data.filter(item => item.realtor === filter.realtor);
        // }
        //
        // if(filter.priceM2To > 0) {
        //     data = data.filter(item => item.priceM2 <= filter.priceM2To);
        // }
        //
        // if(filter.district > 0) {
        //     data = data.filter(item => item.district === filter.district);
        // }
        //
        // if(filter.floorFrom > 0) {
        //     data = data.filter(item => item.floor >= filter.floorFrom);
        // }
        //
        // if(filter.floorTo > 0) {
        //     data = data.filter(item => item.floor <= filter.floorTo);
        // }
        //
        // if(filter.squareFrom > 0) {
        //     data = data.filter(item => item.square >= filter.squareFrom);
        // }
        //
        // if(filter.squareTo > 0) {
        //     data = data.filter(item => item.square <= filter.squareTo);
        // }

        // return data;
    }

    getUserAgency = (user) => {
        let agency = this.props.general.agency.find(item => item.id === user.realtorAgencyId);

        if (agency && agency.id) {
            return agency.name;
        }
        else {
            return user.realtorAgency;
        }
    }

    onTableMouseMove = (event, header, data) => {
        let tableTooltip = {
            data,
            id: data.id,
            show: true,
            x: event.pageX,
            y: event.pageY + 20
        };

        if (header.name === 'realtorName') {
            tableTooltip.html = <div>
                <div>{this.getUserAgency(data)}</div>
                <div>{data.realtorName}</div>
                <div>тел. {data.realtorPhone}</div>
                {
                    data.realtorPrivate !== 1 ?
                        <div style={{ marginTop: 5 }}>
                            <div>Руководитель:</div>
                            <div>тел. {data.realtorPhoneChief}</div>
                        </div>
                        :
                        null
                }
            </div>;

            this.setState({
                tableTooltip
            });
        }
        else if (header.name === 'name') {
            tableTooltip.html = <div>
                <div>{data.name}</div>
                <div>тел. {data.phoneNumber}</div>
            </div>;

            this.setState({
                tableTooltip
            });
        }
    }

    onTableMouseLeave = (event, header, data) => {
        if ((header.name === 'realtorName' || header.name === 'name') && this.state.tableTooltip.id === data.id) {
            let timer = setTimeout(() => {
                this.setState({
                    tableTooltip: {
                        id: 0,
                        show: false
                    }
                });
            }, 100);

            this.setState({
                tableTooltip: {
                    ...this.state.tableTooltip,
                    timer
                }
            });
        }
    }

    onHintMouseOver = () => {
        if (this.state.tableTooltip.timer) {
            clearTimeout(this.state.tableTooltip.timer);
        }
    }

    onHintMouseLeave = () => {
        this.setState({
            tableTooltip: {
                id: 0,
                show: false
            }
        });
    }

    showLayoutWindow = (id, type) => {
        let images = [];
        let data = this.state.data.find(item => item.id === id);

        if (type === 'photos' && typeof data.photoArray === 'string') {
            data.photoArray.split(',').forEach(item => {
                images.push({ original: 'http://' + Api.getAPIUrl() + '/static/photo/' + data.id + '_' + item + '.png' });
            });
        }
        else if (type === 'layout' && typeof data.layoutArray === 'string') {
            data.layoutArray.split(',').forEach(item => {
                images.push({ original: 'http://' + Api.getAPIUrl() + '/static/layout/' + data.id + '_' + item + '.png' });
            });
        }

        this.setState({
            imageGalleryArray: images,
            isImageGalleryShow: true
        }, () => {
            this.imageGallery.fullScreen();
        });

    }

    onGalleryScreenChange = (event) => {
        this.imageGallery.getSnapshotBeforeUpdate = (props, state) => {
            if (!state.isFullscreen && this.state.isImageGalleryShow) {
                this.setState({
                    isImageGalleryShow: false
                });
            }
            else if (state.isFullscreen && !this.state.isImageGalleryShow) {
                this.setState({
                    isImageGalleryShow: true
                });
            }

            return null;
        }
    }

    hideLayoutWindow = (type) => {
        this.setState({
            layoutWindow: {
                ...this.state.layoutWindow,
                isShow: false
            }
        });
    }

    getSellerType = (data) => {
        let type = this.props.general.propertyType.find(item => item.id === data.type)
        if (type) {
            return type.name;
        }
        else {
            return null;
        }
    }

    getWallsMaterial = (data) => {
        let wallsMaterial = this.props.general.wallsMaterial.find(item => item.id === data.wallsMaterial)
        if (wallsMaterial) {
            return wallsMaterial.name;
        }
        else {
            return null;
        }
    }

    getStatusType = (data) => {
        let status = this.props.general.status.find(item => item.id === data.status)
        if (status) {
            return status.name;
        }
        else {
            return null;
        }
    }

    getSubType = (data) => {
        let subType = this.props.general.subType[data.type].find(item => item.id === data.subType)
        if (subType) {
            return subType.name;
        }
        else {
            return null;
        }
    }

    formatDate = (date, epmtyFill = '') => {
        if (!date) {
            return epmtyFill;
        }

        return moment(date).format('DD.MM.YYYY HH:mm:ss');
    }

    selectSeller = (seller) => {
        if (seller) {
            seller._isSelected = !seller._isSelected;

            let selectedObjectsCount = this.state.data.filter(item => item._isSelected).length;

            this.setState({ selectedObjectsCount });
        }
        else {
            const data = this.state.data.map(item => {
                item._isSelected = false;
                return item;
            });

            this.updateData(data);
        }
    }

    setAddress = async (value) => {
        const state = Object.assign({}, this.state);
        state.filter.address = value;

        if (value && value.length > 0) {
            await Api.get('/geo/base_address?name=' + value)
                .then(out => {
                    state.addressSearchBox = {
                        show: true,
                        data: out.value,
                        selectedId: 0
                    }
                })
                .catch(() => { });
        }
        else {
            this.setState({
                ...this.state,
                addressSearchBox: {
                    show: false,
                    selectedId: 0
                },
                filter: {
                    ...this.state.filter,
                    address: '',
                    street: '',
                    street: '',
                    houseNumber: '',
                    houseLetter: ''
                }
            });
            this.state.filter.city = '';
            this.state.filter.street = '';
            this.state.filter.houseNumber = '';
            this.state.filter.houseLetter = '';
            this.setFilter('address', '');
        }

        this.setState(state);
    }

    /*setStreet = async (value) => {
        const state = Object.assign({}, this.state);

        state.filter.street = value;

        if (value && value.length > 0) {
            await Api.get('/geo/street?name=' + value)
                .then(out => {
                    state.streetSearchBox = {
                        show: true,
                        data: out.value,
                        selectedId: 0
                    }
                })
                .catch(() => { });
        }
        else {
            state.streetSearchBox = {
                show: false,
                selectedId: 0
            }
        }

        this.setState(state);
    }*/

    selectAddressSearchBox = (id, title, data) => {
        this.state.filter.city = data.city;
        this.state.filter.street = data.street;
        this.state.filter.houseNumber = data.houseNumber;
        this.state.filter.houseLetter = data.houseLetter;
        this.setState({
            ...this.state,
            addressSearchBox: {
                show: false,
                selectedId: id
            },
            filter: {
                ...this.state.filter,
                address: title,
                street: data.street,
                street: data.city,
                houseNumber: data.houseNumber,
                houseLetter: data.houseLetter
            }
        });
        this.setFilter('address', title);
    }

    resetFilter = () => {
        const filter = {
            id: '',
            type: '0',
            status: '1',
            address: '',
            city: '',
            street: '',
            houseNumber: '',
            houseLetter: '',
            rooms: -1,
            priceFrom: '0',
            priceTo: '0',
            priceM2From: '0',
            realtor: '',
            priceM2To: '0',
            district: 0,
            floorFrom: '',
            floorTo: '',
            floor: '',
            squareFrom: '0',
            squareTo: '0'
        };
        this.setState({
            filter: { ...filter },
            sellerFullInfo: null,
            deal: null,
            showOfferWindow: false,
            addressSearchBox: {
                show: false,
                selectedId: 0
            },
            mode: mode.MY,
            showResetFilter: true
        });
        this.updateSellers({ ...filter, mode: mode.MY, agencyId: this.props.user.agencyId });
    }

    onDealCreated = (data) => {
        Api.post('/deals', data).then(out => {
            this.props.changePage('/deals');
            swal({ title: "Успешно", icon: "success", button: "Ок" });
        })
            .catch(out => {
                swal({ title: "Произошла ошибка", icon: "error", button: "Ок" });
            });
    }

    render = () => {
        const user = this.props.user;
        const rowHighlight = this.props.rowHighlight;
        const filterData = this.state.filter;
        const state = this.state;
        let data = this.state.data;
        let tableStruct = this.tableStructAll;

        data = data.map(item => {
            return ({
                ...item,
                isSelected: user.seller_send_collection ? (<input onChange={() => this.selectSeller(item)} defaultChecked={!!item._isSelected} type="checkbox" />) : '',
                info: <span className="seller-short-info">{item.info}</span>,
                object: <span>{this.getSellerType(item)}<br />{this.getSubType(item)}<br />{this.getStatusType(item)}</span>,
                costHTML: <span><div>{item.cost + 'тр'}</div>{item.priceM2 + 'тр/м2'}</span>,
                floorHTML: <span>{item.floor + '/' + item.floorsCount}
                    <br />{(item.isStudio ? 'Студия' + (item.rooms > 0 ? '+' + item.rooms + 'к' : '') : item.rooms + 'к')}
                    <br />{'пл. кв ' + item.square + ' м2'}
                    <br />{item.landSquare !== null && (item.type !== 1 && item.type !== 2) ? 'пл. уч ' + item.landSquare + ' м2' : '---'}
                    <br />{this.getWallsMaterial(item)}
                </span>,
                squareHTML: item.builtYear + ' г.',
                repairs: this.getRepairType(item),
                layout: <span>{item.photosCount && <span className="link" onClick={() => this.showLayoutWindow(item.id, 'photos')}>{item.photosCount}</span>} / {item.layoutsCount && <span className="link" onClick={() => this.showLayoutWindow(item.id, 'layout')}>{item.layoutsCount}</span>}</span>,
                address: <span><div>{item.city ? item.city + ',' : ''}</div><div>{this.getDistrict(item)}</div>{item.street + ', ' + item.houseNumber}</span>,
                edit: user.seller_edit === 1 ? item.realtor === user.id : (user.seller_edit === 2 ? item.realtorAgencyId === user.agencyId : (user.seller_edit === 3)),
                fullInfo: !!user.seller_info,
                deal: true,
                deleted: !(user.seller_delete === 1 ? item.realtor === user.id : (user.seller_delete === 2 ? item.realtorAgencyId === user.agencyId : (user.seller_delete === 3))),
                remove: !!user.isAdmin,
                deleteReason: item.deleted ? (item.deletedType == 0 ? 'Купил(а)' : (item.deletedInfo ? 'Удален с причиной: ' + item.deletedInfo : '')) : null,
                dates: <span>{this.formatDate(item.creationDate, '-')}<br />{this.formatDate(item.updateDate, '-')}<br />{this.formatDate(item.deletedTime, '-')}</span>,
            });
        });

        let dataMode = this.getMode();

        const count = {
            myCount: data.filter(item => item.realtor === user.id).length,
            agentCount: data.filter(item => item.realtorAgencyId === user.agencyId).length,
            allCount: data.length,
        }

        if (dataMode === mode.MY) {
            tableStruct = this.tableStructMy;
        }
        else if (dataMode === mode.AGENCY) {
            tableStruct = this.tableStructAgency;
        }

        //  filterRoomsList
        let filterRoomsList = new Set();
        data.forEach(item => {
            if (item.rooms > 0) {
                filterRoomsList.add(item.rooms);
            }
        });
        filterRoomsList = Array.from(filterRoomsList);
        filterRoomsList.sort((a, b) => a - b);

        //  filterFloorsList
        let filterFloorsList = new Set();
        data.forEach(item => {
            filterFloorsList.add(item.floor);
        });
        filterFloorsList = Array.from(filterFloorsList);
        filterFloorsList.sort((a, b) => a - b);

        // group by address
        const groupByAddress = (object, item, i) => {
            let isPair = item.id !== object.id && item.district === object.district && item.street === object.street && item.houseNumber === object.houseNumber && item.flatNumber === object.flatNumber;

            if (isPair) {
                if (!data[i].childrens) {
                    data[i].childrens = [Object.assign({}, data[i])];
                }

                data[i].childrens.push(item);
                data[i].edit = false;
                data[i].deleted = false;

                data = data.filter(fItem => fItem.id !== item.id);
            }
        };

        for (let i = 0; i < data.length; i++) {
            let object = data[i];

            data.forEach((item) => groupByAddress(object, item, i));
        }

        return (
            <div>
                {this.state.sellerFullInfo &&
                    <FullInfo data={this.state.sellerFullInfo} onHide={() => this.setState({ sellerFullInfo: null })}></FullInfo>
                }

                {this.state.deal &&
                    <Modal size="lg" className="seller-card" show={true} onHide={() => this.setState({ deal: null })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Новая сделка</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <DealsCreatePage onDealCreated={this.onDealCreated} sellerId={this.state.deal} />
                        </Modal.Body>
                    </Modal>
                }

                <div className="row">
                    <div className="input-group" style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-end'
                    }}>
                        <div>
                            <label htmlFor="kind">Вид</label>
                            <select id="kind" className="custom-select custom-select-sm" value={filterData.type} onChange={(e) => this.setFilter('type', e.target.value)}>
                                <option value="0">-</option>
                                {this.props.general.propertyType.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="status">Статус</label>
                            <select id="status" className="custom-select custom-select-sm" value={filterData.status} onChange={(e) => this.setFilter('status', e.target.value)}>
                                <option value="0">-</option>
                                {this.props.general.status.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
                            </select>
                        </div>

                        <div className="btn-group" role="group">
                            <button onClick={() => this.setMode({ mode: mode.MY })} className={'btn btn-sm ' + (dataMode === mode.MY ? 'active btn-info' : 'btn-light')}>Мои ({this.state.myCount})</button>
                            <button onClick={() => this.setMode({ mode: mode.AGENCY })} className={'btn btn-sm ' + (dataMode === mode.AGENCY ? 'active btn-info' : 'btn-light')}>Агенства ({this.state.agencyCount})</button>
                            <button onClick={() => this.setMode({ mode: mode.ALL })} className={'btn btn-sm ' + (dataMode === mode.ALL ? 'active btn-info' : 'btn-light')}>Все ({this.state.allCount})</button>
                        </div>

                        <div>
                            <label htmlFor="realtorId">Поиск по ID реэлтора</label>
                            <input id="realtorId" className="form-control form-control-sm w-100" type="text" value={filterData.realtor} onChange={(e) => this.setFilter('realtor', e.target.value)} onBlur={(e) => this.setFilter('realtor', +e.target.value || 0)} />
                        </div>

                        {state.selectedObjectsCount > 0 &&
                            <div className="btn-group" role="group">
                                <span onClick={() => this.setState({ showOfferWindow: true })} className={'btn btn-sm btn-info inline-block'}>Отправить подборку</span>
                                <span onClick={() => this.selectSeller()} className={'btn btn-sm btn-light inline-block'}>Сбросить</span>
                            </div>
                        }

                        {this.state.showResetFilter && (<button className="btn btn-sm btn-warning" onClick={() => this.resetFilter()}>Сбросить фильтры</button>)}

                        {user.seller_create ?
                            <div className="right-align">
                                <Link to="./create" onClick={this.props.unsetActiveAd} className="btn btn-sm btn-info">+ Новый объект</Link>
                            </div> : ''
                        }
                    </div>

                    <div className="hr"></div>

                    {this.state.loading ? (<div style={{
                        width: '100%',
                        height: '100%',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        zIndex: 1000,
                        backgroundColor: 'rgba(0,0,0,0.3)'
                    }}></div>) : ''}

                    <div className="input-group filter">
                        <label>
                            <div className="title">№ объекта</div>
                            <input className="form-control form-control-sm" style={{ width: '70px' }} type="text" value={filterData.id} onChange={(e) => this.setFilter('id', (+e.target.value || 0))} />
                        </label>

                        <label>
                            <div className="title">Комнат</div>
                            <select className="custom-select custom-select-sm" value={filterData.rooms} onChange={(e) => this.setFilter('rooms', e.target.value)}>
                                <option value="-1">-</option>
                                <option value="0">Студия</option>
                                {
                                    filterRoomsList.map(item =>
                                        <option key={item} value={item}>{item}к</option>
                                    )
                                }
                            </select>
                        </label>

                        <label>
                            <div className="title">Стоимость т.р.</div>
                            <input className="form-control form-control-sm" style={{ width: '70px' }} type="text" value={filterData.priceFrom} onChange={(e) => this.setFilter('priceFrom', e.target.value)} onBlur={(e) => this.setFilter('priceFrom', +e.target.value || 0)} />
                            -
                            <input className="form-control form-control-sm" style={{ width: '70px' }} type="text" value={filterData.priceTo} onChange={(e) => this.setFilter('priceTo', e.target.value)} onBlur={(e) => this.setFilter('priceTo', +e.target.value || 0)} />
                        </label>

                        <label>
                            <div className="title">Цена т.р. за м2</div>
                            <input className="form-control form-control-sm" style={{ width: '70px' }} type="text" value={filterData.priceM2From} onChange={(e) => this.setFilter('priceM2From', e.target.value)} onBlur={(e) => this.setFilter('priceM2From', +e.target.value || 0)} />
                            -
                            <input className="form-control form-control-sm" style={{ width: '70px' }} type="text" value={filterData.priceM2To} onChange={(e) => this.setFilter('priceM2To', e.target.value)} onBlur={(e) => this.setFilter('priceM2To', +e.target.value || 0)} />
                        </label>

                        <label>
                            <div className="title">Район</div>
                            <select className="custom-select custom-select-sm" value={filterData.district} onChange={(e) => this.setFilter('district', e.target.value)}>
                                <option value={0}>-</option>
                                {
                                    this.props.general.district.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                }
                            </select>
                        </label>

                        <label>
                            <div className="title">Улица</div>
                            <div className="value">
                                <input className="form-control form-control-sm" type="text"
                                    value={filterData.address} onChange={(e) => this.setAddress(e.target.value)} />
                                {this.state.addressSearchBox.show && this.state.addressSearchBox.data &&
                                    <ul className="searchingBox">
                                        {
                                            this.state.addressSearchBox.data.map(item =>
                                                <li key={item.id} onClick={() => this.selectAddressSearchBox(item.id, item.title, item)}>{item.title}</li>
                                            )
                                        }
                                    </ul>
                                }
                            </div>
                        </label>

                        <label>
                            <div className="title">Этаж</div>
                            <select className="custom-select custom-select-sm" value={filterData.floorFrom} onChange={(e) => this.setFilter('floorFrom', e.target.value)}>
                                <option value={0}>-</option>
                                {
                                    filterFloorsList.map(item =>
                                        <option key={item} value={item}>{item}</option>
                                    )
                                }
                            </select>
                            -
                            <select className="custom-select custom-select-sm" value={filterData.floorTo} onChange={(e) => this.setFilter('floorTo', e.target.value)}>
                                <option value={0}>-</option>
                                {
                                    filterFloorsList.map(item =>
                                        <option key={item} value={item}>{item}</option>
                                    )
                                }
                            </select>
                        </label>

                        <label>
                            <div className="title">Площадь м2</div>
                            <input className="form-control form-control-sm" style={{ width: '70px' }} type="text" value={filterData.squareFrom} onChange={(e) => this.setFilter('squareFrom', e.target.value)} onBlur={(e) => this.setFilter('squareFrom', +e.target.value || 0)} />
                            -
                            <input className="form-control form-control-sm" style={{ width: '70px' }} type="text" value={filterData.squareTo} onChange={(e) => this.setFilter('squareTo', e.target.value)} onBlur={(e) => this.setFilter('squareTo', +e.target.value || 0)} />
                        </label>
                    </div>

                    <Table data={data}
                        struct={tableStruct}
                        isApiMode={true}
                        pageChanged={page => {
                            this.setState({ filter: { ...this.state.filter, page } });
                            this.updateSellers({ ...this.state.filter, page, mode: this.state.mode, agencyId: this.props.user.agencyId });
                        }}
                        pageSizeChanged={pageSize => {
                            this.setState({ filter: { ...this.state.filter, pageSize } });
                            this.updateSellers({ ...this.state.filter, pageSize, mode: this.state.mode, agencyId: this.props.user.agencyId });
                        }}
                        dataCount={this.state.dataCount}
                        onAction={this.tableHandler}
                        onMouseMove={this.onTableMouseMove}
                        rowHighlighter={this.rowHighlighter}
                        onMouseLeave={this.onTableMouseLeave} />

                    <div className="input-group table-state">
                        <div>
                            <div style={{ background: rowHighlight[0] }}></div> Ваши объекты
                        </div>
                        <div>
                            <div style={{ background: rowHighlight[2] }}></div> Необходимо отредактировать
                        </div>
                    </div>
                </div>

                {this.state.tableTooltip.show &&
                    <div onMouseOver={this.onHintMouseOver} onMouseLeave={this.onHintMouseLeave} className="hint" style={{ top: this.state.tableTooltip.y, left: this.state.tableTooltip.x }}>
                        <div className="pointer" />
                        <div className="hint-body">
                            {this.state.tableTooltip.html}
                        </div>
                    </div>
                }

                {this.state.isImageGalleryShow &&
                    <ImageGallery ref={el => this.imageGallery = el} onScreenChange={this.onGalleryScreenChange} showIndex={true} items={this.state.imageGalleryArray} />
                }

                {this.state.showOfferWindow &&
                    <OfferWindow data={this.state.data} onHide={() => this.setState({ showOfferWindow: false })} unselect={() => this.selectSeller()} />
                }
            </div>
        )
    }
}

const mapStateToProps = ({ user, general, seller, router }) => ({
    user,
    general,
    seller,
    location: router.location
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            unsetActiveAd,
            setActiveSeller,
            setSellerFilter,
            changePage: (page) => push(page)
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SellersMainPage)
