import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import swal from 'sweetalert'
import { Link } from 'react-router-relative-link'
import downscale from 'downscale'
import Api from '../../../../utils/api'

class AgencyEditPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        chiefId: 0,
        news: '',
        name: '',
        color: ''
      },
      files: {
        logo: null
      }
    };
  }

  updateData = () => {

    if (this.props.isEdit) {
      let activeAgency = this.props.agency.data;

      if (Object.keys(activeAgency).length === 0) {
        this.props.changePage('/admin/agency');
      }

      this.setState({
        data: {
          ...this.state.data,
          ...activeAgency
        }
      });
    }
  }

  componentDidMount() {
    this.updateData();
  }

  save = (e) => {
    e.preventDefault();
    let data = Object.assign({}, this.state.data);
    const files = this.state.files;

    if (!!data.chiefId && !!data.city && !!data.name && !!data.color) {
      if (this.props.onEdit) {
        if (this.props.isEdit) {
          this.props.onEdit({ data, files }, true, this.props.agency.data.id);
        } else {
          this.props.onEdit({ data, files });
        }
      }
    } else {
      swal({ title: "Заполните все поля", icon: "error", button: "Ок" });
    }
  }

  setData = (name, value) => {
    let state = Object.assign({}, this.state);

    state.data[name] = value;
    this.setState(state);
  }


  setFile = (name, value) => {
    if (value && value.length !== 0 && value.length == 1) {
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      const promises = [];
      for (let i = 0; i < value.length; i++) {
        promises.push(new Promise((resolve, reject) => {
          if (allowedExtensions.test(value[i].name)) {
            const fr = new FileReader;
            fr.onload = function () {
              const img = new Image;

              img.onload = function () {

                if (img.width > 1500 || img.height > 1500) {
                  let width = 0, height = 0;
                  if (img.width > 1500 && img.height < 1500) { width = 1500; height = (1500 / img.width) * img.height; }
                  if (img.width < 1500 && img.height > 1500) { width = (1500 / img.height) * img.width; height = 1500; }
                  if (img.width > 1500 && img.height > 1500) {
                    if (img.width >= img.height) {
                      width = 1500; height = (1500 / img.width) * img.height;
                    } else {
                      width = (1500 / img.height) * img.width; height = 1500;
                    }
                  }
                  downscale(value[i], width, height, { returnBlob: 1 })
                    .then(blob => {
                      const file = new File([blob], value[i].name);
                      resolve(file);
                    })
                } else {
                  resolve(value[i]);
                }
              };

              img.src = fr.result;
            };
            fr.readAsDataURL(value[i]);
          } else {
            resolve(value[i]);
          }
        }))
      }
      Promise.all(promises).then(
        data => {
          let state = Object.assign({}, this.state);
          state.files[name] = data;
          this.setState(state);
        }
      );
    } else {
      swal({ title: "Выберите одно изображение", icon: "error", button: "Ок" });
    }
  }

  removeImage = (photoId) => {
    swal({ title: "Вы уверены?", icon: "warning", buttons: ["Нет", "Да"] })
      .then((ok) => {
        if (ok) {
          Api.delete('/agency/image/' + photoId)
            .then(() => {
              this.setState({
                data: {
                  ...this.state.data,
                  photoId: undefined
                }
              });
            })
            .catch(() => { });
        }
      });
  }

  render = () => {
    const data = this.state.data;

    return (
      <form className="row" onSubmit={this.save}>
        <div className="col-12">
          <label>
            <div className="title required">ИД руководителя</div>
            <div className="value">
              <input style={{ width: '50%' }} className="form-control form-control-sm" type="number" value={data.chiefId} onChange={(e) => this.setData('chiefId', +e.target.value || 0)} />
            </div>
          </label>

          <br />

          <label>
            <div className="title required">Город</div>
            <div className="value">
              <select style={{ width: '50%' }} className="custom-select custom-select-sm" value={data.city} onChange={(e) => this.setData('city', e.target.value)}>
                <option value="">Выберите...</option>
                <option value="Ханты-мансийск">Ханты-мансийск</option>
                {this.props.partkladr.cities.map(item => <option value={item.name} key={item.name}>{item.name}</option>)}
              </select>
            </div>
          </label>

          <br />

          <label>
            <div className="title required">Название</div>
            <div className="value">
              <input style={{ width: '50%' }} className="form-control form-control-sm" type="text" value={data.name} onChange={(e) => this.setData('name', e.target.value)} />
            </div>
          </label>

          <br />

          <label>
            <div className="title required">Заливка цветом агентства</div>
            <div className="value">
              <input style={{ width: '50%' }} className="form-control form-control-sm" type="text" value={data.color} onChange={(e) => this.setData('color', e.target.value)} />
            </div>
          </label>

          <br />

          <div>
            <label>
              <div className="title">Лого</div>
              <div className="value">
                <input type="file" className="custom-file-input custom-file-input-sm" multiple onChange={(e) => this.setFile('logo', e.target.files)} />
              </div>
            </label>
            <div className="photoPrev">
              {data.photoId && data.photoId !== 'null' ?
                <div style={{ backgroundImage: 'url(http://' + Api.getAPIUrl() + '/static/logo/' + data.photoId + '.png)' }}>
                  <div className="close" onClick={() => this.removeImage(data.photoId)}>x</div>
                </div> : ''
              }
            </div>
          </div>

          <br />

          <div className="input-group btn-group">
            <button className="btn btn-sm btn-success">Сохранить</button>
            <Link to=".." className="btn btn-sm btn-danger">Отменить</Link>
          </div>
        </div>
      </form>
    )
  }
}

const mapStateToProps = ({ user, agency, general, partkladr, router }) => ({
  user,
  agency,
  general,
  partkladr,
  location: router.location
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: (page) => push(page)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgencyEditPage)
