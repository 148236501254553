import React, { Component } from 'react'
import Api from '../../../../utils/api'

class NotesList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      notes: []
    }
  }

  componentDidMount() {
    this.getNotes()
  }

  getNotes = () => {
    Api.get('/notes').then(out => {
      this.setState({ notes: out })
    })
  }

  createNote = () => {
    setTimeout(() => {
      Api.post('/notes').then(() => {
        this.getNotes()
      })
    }, 200)
  }

  notesChanged = (id, data) => {
    let state = Object.assign({}, this.state),
      note = state.notes.find(item => item.id === id)

    if (note) {
      note.value = data
    }

    this.setState(state)
  }

  notesChangedDone = (id, data) => {
    Api.put('/notes/' + id, { data }).then(() => {
      this.getNotes()
    })
  }

  removeNote = id => {
    Api.delete('/notes/' + id).then(() => {
      this.getNotes()
    })
  }

  render() {
    return (
      <div className={`notes ${this.props.className}`}>
        <h6 className="main__heading">Заметки</h6>
        <button className="btn btn-sm btn-info" onClick={this.createNote}>
          + Новая заметка
        </button>

        <div className="list">
          {this.state.notes.map(note => (
            <div key={note.id}>
              <div className="control">
                <div
                  className="delete"
                  onClick={() => this.removeNote(note.id)}>
                  &#10539;
                </div>
              </div>
              <div className="value">
                <textarea
                  value={note.value}
                  onChange={e => this.notesChanged(note.id, e.target.value)}
                  onBlur={e => this.notesChangedDone(note.id, e.target.value)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default NotesList
