const initialState = {
	sourceFinance: [
		{
			id: 1,
			name: 'Ипотека'
		},
		{
			id: 2,
			name: 'Наличные'
		},
	],
	repairType: [
		{
			id: 1,
			name: 'Косметический'
		},
		{
			id: 2,
			name: 'Современный'
		},
		{
			id: 3,
			name: 'Черновая отделка'
		},
		{
			id: 4,
			name: 'Предчистовая отделка'
		}
	],
	district: [
		{
			id: 0,
			name: '-'
		},
		{
			id: 1,
			name: 'Гидронамыв'
		},
		{
			id: 2,
			name: 'Нагорный'
		},
		{
			id: 3,
			name: 'ОМК'
		},
		{
			id: 4,
			name: 'Самарово'
		},
		{
			id: 5,
			name: 'Центральный'
		},
		{
			id: 6,
			name: 'Югорская звезда'
		},
		{
			id: 8,
			name: 'Учхоз'
		},
		{
			id: 9,
			name: 'АБЗ'
		},
		{
			id: 10,
			name: 'Авиагородок'
		},
		{
			id: 12,
			name: 'Автодорога Нягань'
		},
		{
			id: 13,
			name: 'ВНСС'
		},
		{
			id: 14,
			name: 'Восточная часть города'
		},
		{
			id: 17,
			name: 'д. Согом'
		},
		{
			id: 18,
			name: 'д. Ягурьях'
		},
		{
			id: 19,
			name: 'ДНТ Дорожник-1'
		},
		{
			id: 20,
			name: 'ДНТ Березовое'
		},
		{
			id: 21,
			name: 'ДНТ Иртыш'
		},
		{
			id: 22,
			name: 'ДНТ Черемхи'
		},
		{
			id: 23,
			name: 'ДНТ Черемхи-2'
		},
		{
			id: 24,
			name: 'ДНТ Березовка'
		},
		{
			id: 25,
			name: 'ДНТ Самаровское'
		},
		{
			id: 26,
			name: 'ДНТ Черемхи'
		},
		{
			id: 27,
			name: 'Долина роз'
		},
		{
			id: 28,
			name: 'Западная часть города'
		},
		{
			id: 29,
			name: 'Земляничная'
		},
		{
			id: 30,
			name: 'Иртыш'
		},
		{
			id: 32,
			name: 'Назымская'
		},
		{
			id: 33,
			name: 'Нижневартовский'
		},
		{
			id: 34,
			name: 'Нялино'
		},
		{
			id: 35,
			name: 'Объездная в районе нефтебазы'
		},
		{
			id: 37,
			name: 'п. Бобровский'
		},
		{
			id: 38,
			name: 'п. Батово'
		},
		{
			id: 39,
			name: 'п. Горноправдинск'
		},
		{
			id: 40,
			name: 'п. Горный'
		},
		{
			id: 41,
			name: 'п. Кедровый'
		},
		{
			id: 42,
			name: 'п. Кирпичный'
		},
		{
			id: 43,
			name: 'п. Сибирский'
		},
		{
			id: 44,
			name: 'п. Талинка'
		},
		{
			id: 45,
			name: 'п. Цингалы'
		},
		{
			id: 46,
			name: 'п. Шaпша'
		},
		{
			id: 47,
			name: 'п. Ярки'
		},
		{
			id: 48,
			name: 'п. Карась'
		},
		{
			id: 49,
			name: 'п. Красноленинский'
		},
		{
			id: 50,
			name: 'п. Троица'
		},
		{
			id: 51,
			name: 'пгт. Берёзово'
		},
		{
			id: 52,
			name: 'пгт. Игрим'
		},
		{
			id: 53,
			name: 'Протока Горная'
		},
		{
			id: 54,
			name: 'Протока Неулева'
		},
		{
			id: 55,
			name: 'Протоки Вандыкова'
		},
		{
			id: 56,
			name: 'с. Базьяы'
		},
		{
			id: 57,
			name: 'с. Белогорье'
		},
		{
			id: 58,
			name: 'с. Елизарово'
		},
		{
			id: 59,
			name: 'с. Нялинское'
		},
		{
			id: 60,
			name: 'с. Репилово'
		},
		{
			id: 61,
			name: 'с. Реполово'
		},
		{
			id: 62,
			name: 'с. Троица'
		},
		{
			id: 63,
			name: 'с. Селиярово'
		},
		{
			id: 64,
			name: 'Садовое Общество Водоканал'
		},
		{
			id: 66,
			name: 'СНТ Берег'
		},
		{
			id: 67,
			name: 'СОК Рябинушка'
		},
		{
			id: 68,
			name: 'СОК Строитель'
		},
		{
			id: 69,
			name: 'СОК Ветеран'
		},
		{
			id: 70,
			name: 'СОК Витамин'
		},
		{
			id: 71,
			name: 'СОК Коммунальник'
		},
		{
			id: 72,
			name: 'СОК Родник'
		},
		{
			id: 73,
			name: 'СОК Урожай'
		},
		{
			id: 74,
			name: 'СОНТ Байбалак 1'
		},
		{
			id: 75,
			name: 'СОНТ Байбалак-2'
		},
		{
			id: 76,
			name: 'СОНТ Виктория'
		},
		{
			id: 77,
			name: 'СОНТ Водоканал'
		},
		{
			id: 78,
			name: 'СОНТ Надежда'
		},
		{
			id: 79,
			name: 'СОНТ Путеец'
		},
		{
			id: 80,
			name: 'СОНТ Радость'
		},
		{
			id: 81,
			name: 'СОНТ Расвет'
		},
		{
			id: 82,
			name: 'СОНТ Тайга'
		},
		{
			id: 83,
			name: 'СОНТ Электрон'
		},
		{
			id: 84,
			name: 'СОНТ ЮГРА'
		},
		{
			id: 85,
			name: 'СОНТ Аграрник'
		},
		{
			id: 86,
			name: 'СОНТ Стрижкино'
		},
		{
			id: 87,
			name: 'СОТ Авиатор'
		},
		{
			id: 88,
			name: 'СОТ Автомобилист'
		},
		{
			id: 89,
			name: 'СОТ Агата'
		},
		{
			id: 90,
			name: 'СОТ Белка'
		},
		{
			id: 91,
			name: 'СОТ Бытовик'
		},
		{
			id: 92,
			name: 'СОТ Витамин'
		},
		{
			id: 93,
			name: 'СОТ Газовик'
		},
		{
			id: 94,
			name: 'СОТ Геотранс'
		},
		{
			id: 95,
			name: 'СОТ Геофизик-2'
		},
		{
			id: 96,
			name: 'СОТ Дорожник-1'
		},
		{
			id: 97,
			name: 'СОТ Дорожник-3'
		},
		{
			id: 98,
			name: 'СОТ Дружба'
		},
		{
			id: 99,
			name: 'СОТ Заречный'
		},
		{
			id: 100,
			name: 'СОТ Здоровье'
		},
		{
			id: 101,
			name: 'СОТ Комунальник'
		},
		{
			id: 102,
			name: 'СОТ Кооператор'
		},
		{
			id: 103,
			name: 'СОТ Кузя'
		},
		{
			id: 104,
			name: 'СОТ Лесной'
		},
		{
			id: 105,
			name: 'СОТ Лимпопо'
		}, {
			id: 106,
			name: 'СОТ Маяк'
		},
		{
			id: 107,
			name: 'СОТ Медик 1'
		},
		{
			id: 108,
			name: 'СОТ Медик 2'
		},
		{
			id: 109,
			name: 'СОТ Наука'
		},
		{
			id: 110,
			name: 'СОТ Нептун'
		},
		{
			id: 111,
			name: 'СОТ Парус'
		},
		{
			id: 112,
			name: 'СОТ Полет'
		},
		{
			id: 113,
			name: 'СОТ Поплавок'
		},
		{
			id: 114,
			name: 'СОТ Приозерный'
		},
		{
			id: 115,
			name: 'СОТ Прогресс'
		},
		{
			id: 116,
			name: 'СОТ Прометей'
		},
		{
			id: 117,
			name: 'СОТ Разведчик'
		},
		{
			id: 118,
			name: 'СОТ Разлив'
		},
		{
			id: 119,
			name: 'СОТ Рыбник 2'
		},
		{
			id: 120,
			name: 'СОТ Рыбник'
		},
		{
			id: 121,
			name: 'СОТ Светлана Зона 1'
		}, {
			id: 122,
			name: 'СОТ Связист'
		},
		{
			id: 123,
			name: 'СОТ Связист 2'
		},
		{
			id: 124,
			name: 'СОТ Следопыт'
		},
		{
			id: 125,
			name: 'СОТ Соболь'
		},
		{
			id: 126,
			name: 'СОТ Спартак'
		},
		{
			id: 127,
			name: 'СОТ Стоматолог'
		},
		{
			id: 128,
			name: 'СОТ Тайга 2'
		},
		{
			id: 129,
			name: 'СОТ УПТВСиИС'
		},
		{
			id: 130,
			name: 'СОТ Учитель 3'
		},
		{
			id: 131,
			name: 'СОТ Фиалка'
		},
		{
			id: 132,
			name: 'СОТ Эколог'
		},
		{
			id: 133,
			name: 'СОТ Экспресс'
		},
		{
			id: 134,
			name: 'СОТ Аграрник'
		},
		{
			id: 135,
			name: 'СОТ Аграрник - 2'
		},
		{
			id: 136,
			name: 'СОТ Витамин'
		},
		{
			id: 137,
			name: 'СОТ Геолог'
		},
		{
			id: 138,
			name: 'СОТ Геофизик'
		},
		{
			id: 139,
			name: 'СОТ Движенец'
		},
		{
			id: 141,
			name: 'СОТ Кедр'
		},
		{
			id: 143,
			name: 'СОТ Садовый'
		},
		{
			id: 144,
			name: 'СОТ Садовый 2'
		},
		{
			id: 145,
			name: 'СОТ Строитель'
		},
		{
			id: 146,
			name: 'СОТ Строитель - 2'
		},
		{
			id: 147,
			name: 'СОТ Учитель'
		},
		{
			id: 148,
			name: 'СОТ Ясная поляна'
		},
		{
			id: 149,
			name: 'СОТ Студенческий городок'
		},
		{
			id: 150,
			name: 'СУ-967'
		},
		{
			id: 151,
			name: 'Урoчище Таволожское'
		},
		{
			id: 153,
			name: 'ЦРМ'
		},
	],
	banks: [
		{
			id: 1,
			name: 'ВТБ'
		},
		{
			id: 2,
			name: 'Сбербанк'
		},
		{
			id: 3,
			name: 'Открытие'
		},
		{
			id: 4,
			name: 'Россельхоз'
		},
		{
			id: 5,
			name: 'Запсибкомбанк'
		},
		{
			id: 6,
			name: 'ДельтаКредит'
		},
		{
			id: 8,
			name: 'Не одобрено'
		}
	],
	agency: [
		{
			id: 1,
			name: 'АН Вариант'
		},
		{
			id: 2,
			name: 'АН Этажи'
		},
		{
			id: 3,
			name: 'АН Новый адрес'
		},
		{
			id: 4,
			name: 'АН Мегаполис-снрвис'
		},
		{
			id: 5,
			name: 'АН Сити-Риэлт'
		},
		{
			id: 6,
			name: 'ООО Ипотечное агентство Югры'
		},
		{
			id: 7,
			name: 'АН Юанит'
		},
		{
			id: 8,
			name: 'АН Эксперт'
		},
		{
			id: 9,
			name: 'АН Фортуна'
		},
		{
			id: 10,
			name: 'АН Собственник'
		},
		{
			id: 11,
			name: 'АН Сатурн'
		},
		{
			id: 12,
			name: 'АН Росса'
		},
		{
			id: 13,
			name: 'АН Недвижимость Югры'
		},
		{
			id: 14,
			name: 'АН Кибалион'
		},
		{
			id: 15,
			name: 'Частный риэлтор'
		}
	],
	specialize: [
		{
			id: 1,
			name: 'Продажа недвижимости'
		}
	],
	propertyType: [
		{
			id: 1,
			name: 'Квартира'
		},
		{
			id: 2,
			name: 'Комната'
		},
		{
			id: 3,
			name: 'Дом, Дача, Коттедж' // загородная
		},
		{
			id: 6,
			name: 'Земельный участок'
		},
		{
			id: 4,
			name: 'Коммерческая'
		},
		{
			id: 5,
			name: 'Гаражи и машиноместа'
		}
	],
	roleValue: [
		{
			id: 0,
			name: 'Нет прав'
		},
		{
			id: 1,
			name: 'Свои'
		},
		{
			id: 2,
			name: 'Агенства'
		},
		{
			id: 3,
			name: 'Все'
		}
	],
	subType: {
		// квартиры
		1: [
			{
				id: 4,
				name: 'Вторичное'
			},
			{
				id: 1,
				name: 'Новостройка'
			},
			{
				id: 2,
				name: 'Общежитие'
			},
			{
				id: 3,
				name: 'Апартаменты'
			}
		],
		// комнаты
		2: [
			{
				id: 4,
				name: 'Вторичное'
			},
			{
				id: 1,
				name: 'Новостройка'
			},
			{
				id: 2,
				name: 'Общежитие'
			},
			{
				id: 3,
				name: 'Апартаменты'
			}
		],
		// загородная Дом, Дача, Коттедж
		3: [
			{
				id: 1,
				name: 'СНТ, ДНТ, ИЖС'
			},
			{
				id: 3,
				name: 'Промназначение'
			},
			{
				id: 4,
				name: 'Фермерское хозяйство'
			}
		],
		// коммерческая
		4: [
			{
				id: 2,
				name: 'Офисное'
			},
			{
				id: 8,
				name: 'Свободного назначения'
			},
			{
				id: 1,
				name: 'Торговое'
			},
			{
				id: 7,
				name: 'Складское'
			},
			{
				id: 3,
				name: 'Производственное'
			},
			{
				id: 5,
				name: 'Общественное питание'
			},
			{
				id: 4,
				name: 'Гостиница'
			},
			{
				id: 6,
				name: 'Автосервис'
			},
			{
				id: 9,
				name: 'Здание'
			}
		],
		// гараж или машиноместо
		5: [
			{
				id: 1,
				name: 'Железобетонный'
			}, {
				id: 3,
				name: 'Кирпичный'
			}, {
				id: 4,
				name: 'Металлический'
			}, {
				id: 2,
				name: 'Многоуровневый паркинг'
			}, {
				id: 5,
				name: 'Подземный паркинг'
			}, {
				id: 6,
				name: 'Крытая парковка'
			}, {
				id: 7,
				name: 'Открытая стоянка'
			}
		],
		// Земельный участок
		6: [
			{
				id: 1,
				name: 'СНТ, ДНТ, ИЖС'
			},
			{
				id: 3,
				name: 'Промназначение'
			},
			{
				id: 4,
				name: 'Фермерское хозяйство'
			}
		]
	},
	operation: [
		{
			id: 1,
			name: 'Продажа'
		},
		{
			id: 2,
			name: 'Аренда'
		}
	],
	status: [
		{
			id: 1,
			name: 'Активные'
		},
		{
			id: 2,
			name: 'На модерации'
		},
		{
			id: 3,
			name: 'Приостановленные'
		},
		{
			id: 4,
			name: 'На сделке'
		},
		{
			id: 5,
			name: 'Проданные с нами'
		},
		{
			id: 6,
			name: 'Клиент сам продал'
		},
		{
			id: 7,
			name: 'Удаленные'
		},
	],
	wallsMaterial: [
		{
			id: 1,
			name: 'Кирпичные'
		},
		{
			id: 2,
			name: 'Блочные'
		},
		{
			id: 3,
			name: 'Деревянные'
		},
		{
			id: 4,
			name: 'Каркасно-щитовые'
		},
		{
			id: 5,
			name: 'Кирпичные монолитно-каркасные'
		},
		{
			id: 6,
			name: 'Блочные монолитно-каркасные'
		},
		{
			id: 7,
			name: 'Панельные'
		},
		{
			id: 8,
			name: 'Металлические/тонкостенные'
		}
	],
	ownershipType: [
		{
			id: 1,
			name: 'Долевая собственность'
		},
		{
			id: 2,
			name: 'Собственность'
		}
	],
	location: [
		{
			id: 1,
			name: 'ТЦ/ТРЦ'
		},
		{
			id: 2,
			name: 'В жилом здании'
		},
		{
			id: 3,
			name: 'Отдельно стоящее'
		}
	],
	windows: [
		{
			id: 1,
			name: 'Во двор'
		},
		{
			id: 2,
			name: 'На улицу'
		}
	],
	bathroom: [
		{
			id: 1,
			name: 'Совмещенный'
		},
		{
			id: 2,
			name: 'Раздельный'
		}
	],
	rooms: [
		{
			id: 1,
			name: '1'
		},
		{
			id: 2,
			name: '2'
		},
		{
			id: 3,
			name: '3'
		},
		{
			id: 4,
			name: '4+'
		}
	],
	TypeEnter: [
		{
			id: 1,
			name: 'Со двора'
		},
		{
			id: 2,
			name: 'Отдельный вход'
		}
	]
}

export default (state = initialState, action) => {
	switch (action.type) {
		default:
			return state
	}
}
