export const SET_ACTIVE_AGENCY = 'seller/SET_ACTIVE_AGENCY'

const initialState = {
  data: {}
}

export default (state = initialState, action) => {

  switch (action.type) {

    case SET_ACTIVE_AGENCY:
      return {
        ...state,
        data: action.data
      }

    default:
      return state
  }
}

export const setActiveAgency = (data) => {
  return dispatch => {
    dispatch({
      type: SET_ACTIVE_AGENCY,
      data
    })
  }
}
