import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Api from '../../../../utils/api'

class ClientData extends React.Component {
  constructor(props) {
    super(props);

    this.state = props.state;
    this.setData = props.setData;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.state = nextProps.state;
  }

  render = () => {

    let data = this.state.data;

    return (
      <>
        <label>
          <div className="title required">ФИО продавца</div>
          <div className="value">
            <input className="form-control form-control-sm" type="text" value={data.userName} onChange={(e) => this.setData('userName', e.target.value)} />
          </div>
        </label>

        <label>
          <div className="title required">Телефон клиента</div>
          <div className="value">
            <input className="form-control form-control-sm" type="text" value={data.phoneNumber} onChange={(e) => this.setData('phoneNumber', e.target.value)} />
          </div>
        </label>

        <label>
          <div className="title">Email</div>
          <div className="value">
            <input className="form-control form-control-sm" type="text" value={data.email} onChange={(e) => this.setData('email', e.target.value)} />
          </div>
        </label>

        <label>
          <div className="title">Договор на оказание услуг</div>
          <div className="value">
            <input type="file" multiple onChange={(e) => this.setFile('CFS', e.target.files)} style={{ width: '192px' }} />
          </div>
        </label>
      </>
    )
  }
}

const mapStateToProps = ({ user, general, seller, router }) => ({
  user,
  general,
  seller,
  location: router.location
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: (page) => push(page)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientData)
