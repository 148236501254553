import { combineReducers } from 'redux'
import user from './user'
import general from './general'
import partkladr from './partkladr'
import seller from './seller'
import buyer from './buyer'
import deal from './deal'
import realtor from './realtor'
import news from './news'
import role from './role'
import agency from './agency'

export default combineReducers({
  user,
  seller,
  buyer,
  deal,
  realtor,
  general,
  partkladr,
  news,
  role,
  agency
})
