import React from 'react'
import { Route, Switch } from 'react-router-dom'
import swal from 'sweetalert'
import Api from '../../../../utils/api'
import RoleEditPage from './edit'
import { bindActionCreators } from 'redux'
import { push } from "connected-react-router"
import { connect } from 'react-redux'
import RoleMainPage from './main'

class Roles extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      data: []
    };
  }

  componentWillMount = () => {
    this.updateRole();
  }

  updateRole = () => {
    Api.get('/role')
      .then(data => {
        this.setState({ data: data.value || [] });
      });
  }

  onEdit = (data, isEdit, id) => {
    let form = new FormData(),
      dataKeys = Object.keys(data.data);

    for(let i=0; i<dataKeys.length; i++) {
      form.append(dataKeys[i], data.data[dataKeys[i]]);
    }

    let query;
    if (isEdit) {
      query = Api.put('/role/' + id, form);
    } else {
      query = Api.post('/role', form);
    }


    query.then(out => {
        this.props.changePage('/admin/role');
        swal({ title: "Успешно", icon: "success", button: "Ок" });
      })
      .catch(() => {
        swal({ title: "Произошла ошибка", icon: "error", button: "Ок" });
      });
  }

  render = () => {
    let location = this.props.location;

    return (
      <div>
        <Switch location={location}>
          <Route path="/admin/role/create" component={() => <RoleEditPage onEdit={this.onEdit}/>} />
          <Route path="/admin/role/edit" component={() => <RoleEditPage isEdit={true} onEdit={this.onEdit}/>} />
          <Route path="/admin/role" component={() => <RoleMainPage updateRole={this.updateRole} data={this.state.data}/>} />
        </Switch>
      </div>
    )
  }

}

const mapStateToProps = ({ user}) => ({
  user
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: (page) => push(page)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Roles)
