import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import swal from 'sweetalert'
import { Link } from 'react-router-relative-link'

class RoleEditPage extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      data: {
        name: '',
        ads_show: 0,
        ads_take_work: 0,
        seller_show: 0,
        seller_info: 0,
        seller_send_collection: 0,
        seller_create: 0,
        seller_edit: 0,
        seller_moderation: 0,
        seller_delete: 0,
        seller_delete_forever: 0,
        buyer_show: 0,
        buyer_create: 0,
        buyer_edit: 0,
        buyer_moderation: 0,
        buyer_delete: 0,
        buyer_delete_forever: 0,
        deal_show: 0,
        deal_create: 0,
        deal_edit: 0,
        deal_delete: 0,
        deal_notes: 0,
        user_show: 0,
        user_edit: 0,
        user_block: 0,
        news_show: 0,
        news_create: 0,
        news_edit: 0,
        news_delete: 0
      }
    };
  }

  updateData = () => {

    if (this.props.isEdit) {
      let activeRole = this.props.role.data;

      if (Object.keys(activeRole).length === 0) {
        this.props.changePage('/admin/role');
      }

      this.setState({
        data: {
          ...this.state.data,
          ...activeRole,
          ads_show: Boolean(activeRole.ads_show),
          ads_take_work: Boolean(activeRole.ads_take_work),
          seller_show: Boolean(activeRole.seller_show),
          seller_info: Boolean(activeRole.seller_info),
          seller_send_collection: Boolean(activeRole.seller_send_collection),
          seller_create: Boolean(activeRole.seller_create),
          buyer_show: Boolean(activeRole.buyer_show),
          buyer_create: Boolean(activeRole.buyer_create),
          deal_create: Boolean(activeRole.deal_create),
          user_show: Boolean(activeRole.user_show),
          news_show: Boolean(activeRole.news_show),
          news_create: Boolean(activeRole.news_create),
          news_edit: Boolean(activeRole.news_edit),
          news_delete: Boolean(activeRole.news_delete)
        }
      });
    }
  }

  componentDidMount () {
    this.updateData();
  }

  save = (e) => {
    e.preventDefault();
    let data = Object.assign({}, this.state.data);

    if (!!data.name) {

      data.ads_show = +data.ads_show;
      data.ads_take_work = + data.ads_take_work;
      data.seller_show = + data.seller_show;
      data.seller_info = + data.seller_info;
      data.seller_send_collection = + data.seller_send_collection;
      data.seller_create = + data.seller_create;
      data.seller_edit = + data.seller_edit;
      data.seller_moderation = + data.seller_moderation;
      data.seller_delete = + data.seller_delete;
      data.seller_delete_forever = + data.seller_delete_forever;
      data.buyer_show = + data.buyer_show;
      data.buyer_create = + data.buyer_create;
      data.buyer_edit = + data.buyer_edit;
      data.buyer_moderation = + data.buyer_moderation;
      data.buyer_delete = + data.buyer_delete;
      data.buyer_delete_forever = + data.buyer_delete_forever;
      data.deal_show = + data.deal_show;
      data.deal_create = + data.deal_create;
      data.deal_edit = + data.deal_edit;
      data.deal_delete = + data.deal_delete;
      data.deal_notes = + data.deal_notes;
      data.user_show = + data.user_show;
      data.user_edit = + data.user_edit;
      data.user_block = + data.user_block;
      data.news_show = + data.news_show;
      data.news_create = + data.news_create;
      data.news_edit = + data.news_edit;
      data.news_delete = + data.news_delete;

      if(this.props.onEdit) {
        if (this.props.isEdit) {
          this.props.onEdit({ data }, true, this.props.role.data.id);
        } else {
          this.props.onEdit({ data });
        }
      }
    } else {
      swal({ title: "Заполните все поля", icon: "error", button: "Ок" });
    }
  }

  setData = (name, value) => {
    let state = Object.assign({}, this.state);

    state.data[name] = value;
    this.setState(state);
  }

  render = () => {
    const data = this.state.data;

    return (
      <form className="row" onSubmit={this.save}>
        <div className="col-12">
          <label>
            <div className="title required">Название роли</div>
            <div className="value">
              <input style={{ width: '50%' }} className="form-control form-control-sm" type="text" value={data.name} onChange={(e) => this.setData('name', e.target.value)}/>
            </div>
          </label>

          <br/>

          <h4>Объявления</h4>

          <div className="input-group">
            <label>
              <div className="title">Просматривать раздел</div>
              <div className="value">
                <input type="checkbox" checked={data.ads_show} onChange={(e) => this.setData('ads_show', e.target.checked)}/>
              </div>
            </label>

            <label>
              <div className="title">Брать в работу</div>
              <div className="value">
                <input type="checkbox" checked={data.ads_take_work} onChange={(e) => this.setData('ads_take_work', e.target.checked)}/>
              </div>
            </label>

          </div>

          <br/>

          <h4>Объекты</h4>

          <div className="input-group">
            <label>
              <div className="title">Просматривать раздел</div>
              <div className="value">
                <input type="checkbox" checked={data.seller_show} onChange={(e) => this.setData('seller_show', e.target.checked)}/>
              </div>
            </label>

            <label>
              <div className="title">Просматривать карточку</div>
              <div className="value">
                <input type="checkbox" checked={data.seller_info} onChange={(e) => this.setData('seller_info', e.target.checked)}/>
              </div>
            </label>

            <label>
              <div className="title">Отправлять подборки</div>
              <div className="value">
                <input type="checkbox" checked={data.seller_send_collection} onChange={(e) => this.setData('seller_send_collection', e.target.checked)}/>
              </div>
            </label>

            <label>
              <div className="title">Создавать</div>
              <div className="value">
                <input type="checkbox" checked={data.seller_create} onChange={(e) => this.setData('seller_create', e.target.checked)}/>
              </div>
            </label>

            <label>
              <div className="title">Редактировать</div>
              <div className="value">
                <select className="custom-select custom-select-sm" value={data.seller_edit} onChange={(e) => this.setData('seller_edit', +e.target.value)}>
                  {   this.props.general.roleValue.map(item =>
                        <option value={item.id} key={item.id}>{item.name}</option>
                      )
                  }
                </select>
              </div>
            </label>

            <label>
              <div className="title">Модерировать</div>
              <div className="value">
                <select className="custom-select custom-select-sm" value={data.seller_moderation} onChange={(e) => this.setData('seller_moderation', +e.target.value)}>
                  {   this.props.general.roleValue.map(item =>
                    <option value={item.id} key={item.id}>{item.name}</option>
                  )
                  }
                </select>
              </div>
            </label>

            <label>
              <div className="title">Удалять</div>
              <div className="value">
                <select className="custom-select custom-select-sm" value={data.seller_delete} onChange={(e) => this.setData('seller_delete', +e.target.value)}>
                  {   this.props.general.roleValue.map(item =>
                      <option value={item.id} key={item.id}>{item.name}</option>
                    )
                  }
                </select>
              </div>
            </label>

            <label>
              <div className="title">Удалять безвозвратно</div>
              <div className="value">
                <select className="custom-select custom-select-sm" value={data.seller_delete_forever} onChange={(e) => this.setData('seller_delete_forever', +e.target.value)}>
                  {   this.props.general.roleValue.map(item =>
                        <option value={item.id} key={item.id}>{item.name}</option>
                      )
                  }
                </select>
              </div>
            </label>
          </div>

          <br/>

          <h4>Покупатели</h4>

          <div className="input-group">
            <label>
              <div className="title">Просматривать раздел</div>
              <div className="value">
                <input type="checkbox" checked={data.buyer_show} onChange={(e) => this.setData('buyer_show', e.target.checked)}/>
              </div>
            </label>

            <label>
              <div className="title">Создавать</div>
              <div className="value">
                <input type="checkbox" checked={data.buyer_create} onChange={(e) => this.setData('buyer_create', e.target.checked)}/>
              </div>
            </label>

            <label>
              <div className="title">Редактировать</div>
              <div className="value">
                <select className="custom-select custom-select-sm" value={data.buyer_edit} onChange={(e) => this.setData('buyer_edit', +e.target.value)}>
                  {   this.props.general.roleValue.map(item =>
                    <option value={item.id} key={item.id}>{item.name}</option>
                  )
                  }
                </select>
              </div>
            </label>

            <label>
              <div className="title">Модерировать</div>
              <div className="value">
                <select className="custom-select custom-select-sm" value={data.buyer_moderation} onChange={(e) => this.setData('buyer_moderation', +e.target.value)}>
                  {   this.props.general.roleValue.map(item =>
                    <option value={item.id} key={item.id}>{item.name}</option>
                  )
                  }
                </select>
              </div>
            </label>

            <label>
              <div className="title">Удалять</div>
              <div className="value">
                <select className="custom-select custom-select-sm" value={data.buyer_delete} onChange={(e) => this.setData('buyer_delete', +e.target.value)}>
                  {   this.props.general.roleValue.map(item =>
                    <option value={item.id} key={item.id}>{item.name}</option>
                  )
                  }
                </select>
              </div>
            </label>

            <label>
              <div className="title">Удалять безвозвратно</div>
              <div className="value">
                <select className="custom-select custom-select-sm" value={data.buyer_delete_forever} onChange={(e) => this.setData('buyer_delete_forever', +e.target.value)}>
                  {   this.props.general.roleValue.map(item =>
                    <option value={item.id} key={item.id}>{item.name}</option>
                  )
                  }
                </select>
              </div>
            </label>
          </div>

          <br/>

          <h4>Сделки</h4>

          <div className="input-group">
            <label>
              <div className="title">Просматривать раздел</div>
              <div className="value">
                <select className="custom-select custom-select-sm" value={data.deal_show} onChange={(e) => this.setData('deal_show', +e.target.value)}>
                  {   this.props.general.roleValue.map(item =>
                    <option value={item.id} key={item.id}>{item.name}</option>
                  )
                  }
                </select>
              </div>
            </label>

            <label>
              <div className="title">Создавать</div>
              <div className="value">
                <input type="checkbox" checked={data.deal_create} onChange={(e) => this.setData('deal_create', e.target.checked)}/>
              </div>
            </label>

            <label>
              <div className="title">Редактировать</div>
              <div className="value">
                <select className="custom-select custom-select-sm" value={data.deal_edit} onChange={(e) => this.setData('deal_edit', +e.target.value)}>
                  {   this.props.general.roleValue.map(item =>
                    <option value={item.id} key={item.id}>{item.name}</option>
                  )
                  }
                </select>
              </div>
            </label>

            <label>
              <div className="title">Удалять</div>
              <div className="value">
                <select className="custom-select custom-select-sm" value={data.deal_delete} onChange={(e) => this.setData('deal_delete', +e.target.value)}>
                  {   this.props.general.roleValue.map(item =>
                    <option value={item.id} key={item.id}>{item.name}</option>
                  )
                  }
                </select>
              </div>
            </label>

            <label>
              <div className="title">Создавать и удалять заметки</div>
              <div className="value">
                <select className="custom-select custom-select-sm" value={data.deal_notes} onChange={(e) => this.setData('deal_notes', +e.target.value)}>
                  {   this.props.general.roleValue.map(item =>
                    <option value={item.id} key={item.id}>{item.name}</option>
                  )
                  }
                </select>
              </div>
            </label>
          </div>

          <br/>

          <h4>Пользователи</h4>

          <div className="input-group">
            <label>
              <div className="title">Просматривать раздел</div>
              <div className="value">
                <input type="checkbox" checked={data.user_show} onChange={(e) => this.setData('user_show', e.target.checked)}/>
              </div>
            </label>

            <label>
              <div className="title">Редактировать</div>
              <div className="value">
                <select className="custom-select custom-select-sm" value={data.user_edit} onChange={(e) => this.setData('user_edit', +e.target.value)}>
                  {   this.props.general.roleValue.map(item =>
                    <option value={item.id} key={item.id}>{item.name}</option>
                  )
                  }
                </select>
              </div>
            </label>

            <label>
              <div className="title">Блокировать/Активировать</div>
              <div className="value">
                <select className="custom-select custom-select-sm" value={data.user_block} onChange={(e) => this.setData('user_block', +e.target.value)}>
                  {   this.props.general.roleValue.map(item =>
                    <option value={item.id} key={item.id}>{item.name}</option>
                  )
                  }
                </select>
              </div>
            </label>
          </div>

          <br/>

          <h4>Новости</h4>

          <div className="input-group">
            <label>
              <div className="title">Просматривать раздел</div>
              <div className="value">
                <input type="checkbox" checked={data.news_show} onChange={(e) => this.setData('news_show', e.target.checked)}/>
              </div>
            </label>

            <label>
              <div className="title">Создавать</div>
              <div className="value">
                <input type="checkbox" checked={data.news_create} onChange={(e) => this.setData('news_create', e.target.checked)}/>
              </div>
            </label>

            <label>
              <div className="title">Удалять</div>
              <div className="value">
                <input type="checkbox" checked={data.news_delete} onChange={(e) => this.setData('news_delete', e.target.checked)}/>
              </div>
            </label>

            <label>
              <div className="title">Редактировать</div>
              <div className="value">
                <input type="checkbox" checked={data.news_edit} onChange={(e) => this.setData('news_edit', e.target.checked)}/>
              </div>
            </label>
          </div>

          <br/>

          <div className="input-group btn-group">
            <button className="btn btn-sm btn-success">Сохранить</button>
            <Link to=".." className="btn btn-sm btn-danger">Отменить</Link>
          </div>
        </div>
      </form>
    )
  }
}

const mapStateToProps = ({ user, role, general, router }) => ({
  user,
  role,
  general,
  location: router.location
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: (page) => push(page)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleEditPage)
