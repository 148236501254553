import React from 'react'

const CountInfoCard = ({ data, ...props }) => {
  const { title, titleNumber, count, maxCount, bgColor } = data

  return (
    <div className={`count-info-card ${props.className}`}>
      <div className="count-info-card__header">
        <span>{titleNumber}</span>
        <p>{title}</p>
      </div>
      <div className="count-info-card__body">
        <div className="count-info-card__body-left">
          <span>{count}</span>
          <span>/{maxCount}</span>
        </div>
        <div className="count-info-card__body-right">
          <span className={`bg-${bgColor}`} />

          <p>в этом месяце</p>
        </div>
      </div>
    </div>
  )
}

export default CountInfoCard
