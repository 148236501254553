import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class mainParameters extends React.Component {
  constructor(props) {
    super(props);

    this.state = props.state;
    this.setData = props.setData;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.state = nextProps.state;
  }

  render = () => {
    let data = this.state.data;
    let user = this.props.user;
    return (
      <>
        <div>
          <label>
            <div className="title required">Вид недвижимости</div>
            <div className="value">
              <select className="custom-select custom-select-sm" value={data.type} onChange={(e) => this.setData('type', e.target.value)}>
                <option disabled>Выберите...</option>
                {this.props.general.propertyType.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
              </select>
            </div>
          </label>

          <label>
            <div className="title required">Тип недвижимости</div>
            <div className="value">
              <select className="custom-select custom-select-sm" value={data.subType} onChange={(e) => { console.log(e.target); this.setData('subType', e.target.value) }}>
                <option value="" disabled>Выберите...</option>
                {this.props.general.subType[this.state.data.type].map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
              </select>
            </div>
          </label>

          <label>
            <div className="title required">Операция</div>
            <div className="value">
              <select className="custom-select custom-select-sm" value={data.operation} onChange={(e) => this.setData('operation', e.target.value)}>
                {this.props.general.operation.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
              </select>
            </div>
          </label>

          <label>
            <div className="title required">Статус</div>
            <div className="value">
              <select className="custom-select custom-select-sm" value={data.status} onChange={(e) => this.setData('status', e.target.value)}>
                <option value="" selected aria-disabled>Выберите...</option>
                {this.props.isEdit ? this.props.general.status.filter(item => item.id !== 2)
                  .filter(item => user.seller_delete === 1 ? (user.id === item.realtor ? true : item.id !== 7) :
                    (user.seller_delete === 2 ? (user.agencyId === item.realtorAgencyId ? true : item.id !== 7) :
                      (user.seller_delete === 3 ? true : item.id !== 7)))
                  .map(item => <option value={item.id} key={item.id}>{item.name}</option>) :
                  (<option value="2">На модерации</option>)}
              </select>
            </div>
          </label>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ user, general, seller, router }) => ({
  user,
  general,
  seller,
  location: router.location
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: (page) => push(page)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mainParameters)
