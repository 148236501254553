import React from 'react'
import { bindActionCreators } from 'redux'
import { setActiveNews } from '../../../../modules/news'
import { push } from "connected-react-router"
import { connect } from 'react-redux'
import { Field, FieldType, SortableField, Table } from '../../table'
import Api from '../../../../utils/api'
import swal from 'sweetalert'

class NewsTypeMainPage extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
    }

    this.tableStruct = [
      SortableField(FieldType.NONE, 'id', 'ID'),
      SortableField(FieldType.NONE, 'newsType', 'Раздел'),
      SortableField(FieldType.BUTTON, 'delete', 'Удалить'),
    ];

  }

  tableHandler = (action, value) => {
    let item = this.props.data.find(item => item.id === value);

    switch (action) {
      case 'delete':
        if(item) {
          swal({ title: "Вы уверены?", icon: "warning", buttons: ["Нет", "Да"] })
            .then((ok) => {
              if(ok) {
                Api.delete('/news-type/' + item.id)
                  .then(data => {
                    this.setState({ data: data.value || [] });
                    this.props.updateNewsType();
                  });
              }
            });
        }

        break;

      default:
        break;
    }
  }

  componentDidMount() {
  }

  render() {
    let data = this.props.data || [];

    data = data.map(item => {
      return ({
        ...item,
        delete: true
      })
    });

    return (
      <div className="row">
        <div>
          <button className="btn btn-sm btn-info" onClick={() => this.props.changePage('/admin/news-type/create')}>Создать</button>
        </div>
        <div style={{overflow: 'hidden'}}>
          <Table data={data} struct={this.tableStruct} onAction={this.tableHandler}/>
        </div>
      </div>
    )
  }

}

const mapStateToProps = ({ user, general, router }) => ({
  user,
  general,
  location: router.location
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setActiveNews,
      changePage: (page) => push(page)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsTypeMainPage)
