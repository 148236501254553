import React from 'react'
import { Route, Switch } from 'react-router-dom'
import swal from 'sweetalert'
import Api from '../../../../utils/api'
import AgencyEditPage from './edit'
import { bindActionCreators } from 'redux'
import { push } from "connected-react-router"
import { connect } from 'react-redux'
import AgencyMainPage from './main'

class Agency extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      data: []
    };
  }

  componentWillMount = () => {
    this.updateAgency();
  }

  updateAgency = () => {
    Api.get('/agency')
      .then(data => {
        this.setState({ data: data.value || [] });
      });
  }

  onEdit = (data, isEdit = false, id) => {
    let form = new FormData(),
      dataKeys = Object.keys(data.data),
      fileKeys = Object.keys(data.files);

    for(let i=0; i<dataKeys.length; i++) {
      form.append(dataKeys[i], data.data[dataKeys[i]]);
    }

    for(let i=0; i<fileKeys.length; i++) {
      let files = data.files[fileKeys[i]];

      if(!files) {
        continue;
      }

      for(let j=0; j<files.length; j++) {
        form.append(fileKeys[i], files[j]);
      }
    }

    let query;
    if (isEdit) {
      query = Api.put('/agency/' + id, form);
    } else {
      query = Api.post('/agency', form);
    }

    query.then(out => {
        this.props.changePage('/admin/agency');
        swal({ title: "Успешно", icon: "success", button: "Ок" });
      })
      .catch(() => {
        swal({ title: "Произошла ошибка", icon: "error", button: "Ок" });
      });
  }

  render = () => {
    let location = this.props.location;

    return (
      <div>
        <Switch location={location}>
          <Route path="/admin/agency/create" component={() => <AgencyEditPage onEdit={this.onEdit}/>} />
          <Route path="/admin/agency/edit" component={() => <AgencyEditPage isEdit={true} onEdit={this.onEdit}/>} />
          <Route path="/admin/agency" component={() => <AgencyMainPage updateAgency={this.updateAgency} data={this.state.data}/>} />
        </Switch>
      </div>
    )
  }

}

const mapStateToProps = ({ user, news }) => ({
  user,
  news
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: (page) => push(page)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Agency)
