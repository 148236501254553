import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Api from '../../../../utils/api'
import swal from 'sweetalert'

class Parameter extends React.Component {
  constructor(props) {
    super(props);

    this.state = props.state;
    this.setData = props.setData;
    this.validateData = props.validateData;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.state = nextProps.state;
  }

  cost(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Стоимость т.р.</div>
        <div className="value">
          <input className="form-control form-control-sm" type="text" value={data.cost} onChange={(e) => this.setData('cost', e.target.value)} onBlur={(e) => this.validateData('cost', /^[0-9]{1,6}$/, parseInt(e.target.value), 'Стоимость может содержать до 6 цифр!')} />
        </div>
      </label>
    )
  }

  square(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Площадь м2</div>
        <div className="value">
          <input className="form-control form-control-sm small-input" type="text" value={data.square} onChange={(e) => this.setData('square', e.target.value)} onBlur={(e) => this.setData('square', (+e.target.value || 0).toFixed(2))} />
        </div>
      </label>
    )
  }

  studio() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Студия</div>
        <div className="value">
          <input type="checkbox" checked={data.isStudio} onChange={(e) => this.setData('isStudio', +e.target.checked)} />
        </div>
      </label>
    )
  }

  rooms(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Количество комнат</div>
        <div className="value">
          <select className="custom-select custom-select-sm" value={data.rooms} onChange={(e) => this.setData('rooms', parseInt(e.target.value))}>
            {this.props.general.rooms.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
          </select>
        </div>
      </label>
    )
  }

  repairs(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Ремонт</div>
        <div className="value">
          <select className="custom-select custom-select-sm" value={data.repairs} onChange={(e) => this.setData('repairs', parseInt(e.target.value))}>
            {this.props.general.repairType.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
          </select>
        </div>
      </label>
    )
  }

  roomSquare(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Площадь комнаты</div>
        <div className="value">
          <input className="form-control form-control-sm small-input" type="text" value={data.roomSquare} onChange={(e) => this.setData('roomSquare', parseInt(e.target.value) || 0)} />
        </div>
      </label>
    )
  }

  ownershipType() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Вид собственности</div>
        <div className="value">
          <select className="custom-select custom-select-sm" value={data.ownershipType} onChange={(e) => this.setData('ownershipType', parseInt(e.target.value))}>
            <option value="">Выберите...</option>
            {this.props.general.ownershipType.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
          </select>
        </div>
      </label>
    )
  }

  landSquare(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Площадь. сот</div>
        <div className="value">
          <input className="form-control form-control-sm small-input" type="text" value={data.landSquare} onChange={(e) => this.setData('landSquare', parseInt(e.target.value) || 0)} />
        </div>
      </label>
    )
  }

  floor(required, text = "") {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Этаж</div>
        <div className="value">
          <input className="form-control form-control-sm middle-input" type="number" value={data.floor} onChange={(e) => this.setData('floor', parseInt(e.target.value))} /> {text}
        </div>
      </label>
    )
  }

  windows() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Окна</div>
        <div className="value">
          <select className="custom-select custom-select-sm" value={data.windows} onChange={(e) => this.setData('windows', parseInt(e.target.value))}>
            <option value="">Выберите...</option>
            {this.props.general.windows.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
          </select>
        </div>
      </label>
    )
  }

  bathroom() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Санузел</div>
        <div className="value">
          <select className="custom-select custom-select-sm" value={data.bathroom} onChange={(e) => this.setData('bathroom', parseInt(e.target.value))}>
            <option value="">Выберите...</option>
            {this.props.general.bathroom.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
          </select>
        </div>
      </label>
    )
  }

  ceilingHeight(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Высота потолков</div>
        <div className="value">
          <input className="form-control form-control-sm small-input" type="text" value={data.ceilingHeight} onChange={(e) => this.setData('ceilingHeight', e.target.value)} onBlur={(e) => this.setData('ceilingHeight', (+e.target.value || 0).toFixed(2))} /> м
        </div>
      </label>
    )
  }

  isBalcony() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Балкон</div>
        <div className="value">
          <input type="checkbox" checked={data.isBalcony} onChange={(e) => this.setData('isBalcony', +e.target.checked)} />
        </div>
      </label>
    )
  }

  isLoggia() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Лоджия</div>
        <div className="value">
          <input type="checkbox" checked={data.isLoggia} onChange={(e) => this.setData('isLoggia', +e.target.checked)} />
        </div>
      </label>
    )
  }

  isWardrobe() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Гардеробная</div>
        <div className="value">
          <input type="checkbox" checked={data.isWardrobe} onChange={(e) => this.setData('isWardrobe', +e.target.checked)} />
        </div>
      </label>
    )
  }

  isPanoramicWindows() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Панорамные окна</div>
        <div className="value">
          <input type="checkbox" checked={data.isPanoramicWindows} onChange={(e) => this.setData('isPanoramicWindows', +e.target.checked)} />
        </div>
      </label>
    )
  }

  isElectric() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Электричество</div>
        <div className="value">
          <input type="checkbox" checked={data.isElectric} onChange={(e) => this.setData('isElectric', +e.target.checked)} />
        </div>
      </label>
    )
  }

  isGas() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Газ</div>
        <div className="value">
          <input type="checkbox" checked={data.isGas} onChange={(e) => this.setData('isGas', +e.target.checked)} />
        </div>
      </label>
    )
  }

  isDrillingWell() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Скважина</div>
        <div className="value">
          <input type="checkbox" checked={data.isDrillingWell} onChange={(e) => this.setData('isDrillingWell', +e.target.checked)} />
        </div>
      </label>
    )
  }

  isSewerage() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Канализация</div>
        <div className="value">
          <input type="checkbox" checked={data.isSewerage} onChange={(e) => this.setData('isSewerage', +e.target.checked)} />
        </div>
      </label>
    )
  }

  isSepticTank() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Септик</div>
        <div className="value">
          <input type="checkbox" checked={data.isSepticTank} onChange={(e) => this.setData('isSepticTank', +e.target.checked)} />
        </div>
      </label>
    )
  }

  isWaterPipes() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Водопровод</div>
        <div className="value">
          <input type="checkbox" checked={data.isWaterPipes} onChange={(e) => this.setData('isWaterPipes', +e.target.checked)} />
        </div>
      </label>
    )
  }

  floorsCount(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Этажность</div>
        <div className="value">
          <input className="form-control form-control-sm middle-input" type="number" value={data.floorsCount} onChange={(e) => this.setData('floorsCount', parseInt(e.target.value))} />
        </div>
      </label>
    )
  }

  wallsMaterial(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Материал стен</div>
        <div className="value">
          <select className="custom-select custom-select-sm" value={data.wallsMaterial} onChange={(e) => this.setData('wallsMaterial', e.target.value)}>
            <option value="">Выберите...</option>
            {this.props.general.wallsMaterial.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
          </select>
        </div>
      </label>
    )
  }

  builtYear(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Год постройки</div>
        <div className="value">
          <input className="form-control form-control-sm" type="number" value={data.builtYear} onChange={(e) => this.setData('builtYear', parseInt(e.target.value))} onBlur={(e) => this.validateData('builtYear', /^[0-9]{4}$/, parseInt(e.target.value), 'Год постройки необходимо указывать в формате хххх!')} />
        </div>
      </label>
    )
  }

  notCompletedBuilding() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Недострой</div>
        <div className="value">
          <input type="checkbox" checked={data.isNotCompletedBuilding} onChange={(e) => this.setData('isNotCompletedBuilding', +e.target.checked)} />
        </div>
      </label>
    )
  }

  parkingEnter() {
    let data = this.state.data;
    return (
      <div className="col-4">
        <label>
          <div className="title">Парковка</div>
        </label>
        <div className="value">
          <label>
            <input type="checkbox" checked={data.isParkingOutside} onChange={(e) => this.setData('isParkingOutside', +e.target.checked)} />
            С улицы
          </label>
        </div>
        <div className="value">
          <label>
            <input type="checkbox" checked={data.isParkingInside} onChange={(e) => this.setData('isParkingInside', +e.target.checked)} />
            В здании
          </label>
        </div>
      </div>
    )
  }

  TypeEnter(required) {
    let data = this.state.data;
    return (
      <label>
        <div className={`title ${required}`}>Материал стен</div>
        <div className="value">
          <select className="custom-select custom-select-sm" value={data.TypeEnter} onChange={(e) => this.setData('TypeEnter', e.target.value)}>
            {this.props.general.TypeEnter.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
          </select>
        </div>
      </label>
    )
  }

  isSeveralFloors() {
    let data = this.state.data;
    return (
      <label>
        <div className="title">Несколько этажей</div>
        <div className="value">
          <input type="checkbox" checked={data.isSeveralFloors} onChange={(e) => this.setData('isSeveralFloors', +e.target.checked)} />
        </div>
      </label>
    )
  }

  renderParams(type) {

    switch (parseInt(type)) {
      case 1:
        return (
          <>
            <div className="input-group">
              {this.studio()}
              {this.rooms("required")}
              {this.square("required")}
              {this.cost("required")}
              {this.floor("required")}
              {this.repairs("required")}
              {this.ownershipType()}
            </div>
            <div className="input-group">
              {this.windows()}
              {this.bathroom()}
              {this.ceilingHeight()}
            </div>
            <div className="input-group">
              {this.isBalcony()}
              {this.isLoggia()}
              {this.isWardrobe()}
              {this.isPanoramicWindows()}
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="input-group">
              {this.rooms("required")}
              {this.square("required")}
              {this.cost("required")}
              {this.floor("required")}
              {/*{this.roomSquare("required")}*/}
              {this.repairs("required")}
              {this.ownershipType()}
            </div>
            <div className="input-group">
              {this.windows()}
              {this.bathroom()}
              {this.ceilingHeight()}
            </div>
            <div className="input-group">
              {this.isBalcony()}
              {this.isLoggia()}
              {this.isWardrobe()}
              {this.isPanoramicWindows()}
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="input-group">
              {this.cost("required")}
              {this.landSquare("required")}
              {this.square("required")}
              {this.notCompletedBuilding()}
            </div>
            <div className="input-group">
              {this.floorsCount("required")}
              {this.wallsMaterial()}
              {this.repairs()}
              {this.builtYear()}
              {this.rooms()}
            </div>
            <div className="input-group">
              {this.isElectric()}
              {this.isGas()}
              {this.isDrillingWell()}
              {this.isSewerage()}
              {this.isSepticTank()}
              {this.isWaterPipes()}
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="input-group">
              {this.cost("required")}
              {this.square("required")}
              {this.ceilingHeight()}
              {this.rooms()}
              {this.repairs("required")}
            </div>
            <div className="input-group">
              {this.TypeEnter()}
              {this.parkingEnter()}
            </div>
            <div className="input-group">
              {this.floor("required")}
              {this.isSeveralFloors()}
              {this.wallsMaterial()}
              {this.builtYear()}
            </div>
          </>
        );
      case 5:
        return (
          <>
            <div className="input-group">
              {this.cost("required")}
              {this.square()}
              {this.repairs()}
            </div>
            <div className="input-group">
              {this.floor("required", "из")}
              {this.floorsCount()}
              {this.builtYear()}
            </div>
          </>
        );
      case 6:
        return (
          <>
            <div className="input-group">
              {this.cost("required")}
              {this.landSquare("required")}
            </div>
            <div className="input-group">
              {this.isElectric()}
              {this.isGas()}
              {this.isDrillingWell()}
              {this.isSewerage()}
              {this.isSepticTank()}
              {this.isWaterPipes()}
            </div>
          </>
        );
    }

  }



  render = () => {

    return (
      <>
        <div className="input-group">

          {this.renderParams(this.state.data.type)}

        </div>
      </>
    )
  }
}

const mapStateToProps = ({ user, general, seller, router }) => ({
  user,
  general,
  seller,
  location: router.location
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: (page) => push(page)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Parameter)
