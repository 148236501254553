import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Api from '../../../../utils/api'

export default class DealsList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: []
    }
  }

  componentDidMount = () => {
    this.updateDeals()
  }

  updateDeals = () => {
    Api.get('/deals').then(data => {
      this.setState({ data: data.value || [] })
      console.log(data)
    })
  }

  render() {
    return (
      <div className={this.props.className}>
        <h6 className="main__heading">Дела по сделкам</h6>
        <table className="main__table table table-borderless">
          <thead>
            <tr>
              <th scope="col">Клиент</th>
              <th scope="col">Объект</th>
              <th scope="col">Задача</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.length &&
              this.state.data.map(deal => (
                <tr key={deal.id}>
                  <td>{deal.sellerName}</td>
                  <td>{deal.objectName}</td>
                  <td>@mdo</td>
                </tr>
              ))}
          </tbody>
        </table>

        <Link className="btn btn-sm btn-info" to="deals">
          Перейти к сделкам
        </Link>
      </div>
    )
  }
}
