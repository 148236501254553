import React from 'react'
import { Link } from 'react-router-relative-link'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Api from '../../../../utils/api'
import BuildingCreatePage from './buiding'
import ParameterCreatePage from './parameter'
import LocationCreatePage from './location'
import swal from 'sweetalert'
import Administrator from "./adminisrtrator";
import MainParameters from "./mainParameters";
import ClientData from "./clientData";
import downscale from "downscale/dist/downscale"


class SellersCreatePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {

                // mainParameters.js
                type: 1,
                subType: 1,
                operation: 1,
                status: 1,

                // userData.js
                userName: "",
                phoneNumber: "+7",
                email: "",

                // location.js
                city: "",
                location: 1,
                isGardenCommunity: 0,
                isInCity: 0,
                landNumber: "0",
                district: '',
                street: "",
                houseNumber: "",
                houseLetter: "",
                houseCorpse: "",
                flatNumber: "",
                cadastral: "0",
                metro: "",
                address: 'г ' + this.props.user.city + ', ',

                // parameters.js
                cost: "0",
                square: "0.00",
                isStudio: 0,
                rooms: 1,
                floor: 0,
                repairs: 1,
                isChecked: 0,
                roomSquare: 0,
                ownershipType: 1,
                landSquare: 0,
                windows: 0,
                bathroom: 0,
                ceilingHeight: "2.70",
                isBalcony: 0,
                isLoggia: 0,
                isWardrobe: 0,
                isPanoramicWindows: 0,

                isElectric: 0,
                isDrillingWell: 0,
                isSewerage: 0,
                isSepticTank: 0,
                isWaterPipes: 0,

                TypeEnter: 0,
                isParkingOutside: 0,
                isParkingInside: 0,
                isSeveralFloors: 0,

                // building.js
                floorsCount: 0,
                wallsMaterial: 1,
                builtYear: new Date().getFullYear(),
                isNotCompletedBuilding: 0,
                isGas: 0,
                isPlanDemolition: 0,
                isProtectedPlace: 0,
                isChildrenPlace: 0,
                isSportPlace: 0,
                isUndergroundParking: 0,
                isGroundParking: 0,
                isSlagbaum: 0,
                isServiceLift: 0,
                isPassengerLift: 0,

                // admininstrator.js
                isModerated: 0,

                // create.js
                info: "",
                realtorComment: "",

            },
            files: {
                CFS: null,
                layout: null,
                photo: null
            },
            activeAd: null,
            addressSearchBox: {
                show: false,
                selectedId: 0
            }
        };
    }

    componentDidMount() {
        let activeAd = this.props.user.activeAd;

        if (!this.props.isEdit && this.props.user && this.props.user.city) {
            this.setState({
                data: {
                    ...this.state.data,
                    city: this.props.user.city,
                    address: 'г ' + this.props.user.city + ', '
                }
            })
        }

        if (this.props.isEdit) {
            let activeSeller = this.props.seller.data;
            if (!activeSeller.type) { activeSeller.type = ''; }
            if (!activeSeller.subType) { activeSeller.subType = ''; }
            if (!activeSeller.status) { activeSeller.status = ''; }
            else { activeSeller.lastStatus = activeSeller.status; }
            if (!activeSeller.city) { activeSeller.city = ''; }
            if (!activeSeller.operation) { activeSeller.operation = ''; }
            if (!activeSeller.ownershipType) { activeSeller.ownershipType = ''; }
            if (!activeSeller.wallsMaterial) { activeSeller.wallsMaterial = ''; }
            if (!activeSeller.location) { activeSeller.location = ''; }
            if (!activeSeller.district) { activeSeller.district = ''; }
            if (!activeSeller.isChecked) { activeSeller.isChecked = '0'; }

            this.setState({
                data: {
                    ...this.state.data,
                    ...activeSeller,
                    userName: activeSeller.name || ''
                },
                addressSearchBox: {
                    ...this.state.addressSearchBox,
                    show: false,
                    selectedId: -1
                }
            });
        }
        else if (activeAd) {
            this.setState({
                activeAd,
                data: {
                    ...this.state.data,
                    phoneNumber: activeAd.phone
                }
            });
        }
    }

    errorMsg = (msg) => {
        swal({ text: msg, icon: 'error', button: 'Ок' });
    }

    checkData(type) {

        let data = Object.assign({}, this.state.data);

        switch (parseInt(type)) {
            case 1:
                return (
                    (!data.userName) ||
                    (!data.city || !data.district || !data.street || !data.houseNumber) ||
                    (!data.cost || !data.square || !data.rooms || !data.repairs) ||
                    (!data.floor || !data.floorsCount || !data.wallsMaterial || !data.builtYear)
                );
            case 2:
                return (
                    (!data.userName) ||
                    (!data.city || !data.district || !data.street || !data.houseNumber) ||
                    (!data.cost || !data.square || !data.rooms || !data.repairs) ||
                    (!data.floor || !data.floorsCount || !data.wallsMaterial || !data.builtYear)
                );
            case 3:
                return (
                    (!data.userName) ||
                    (!data.city || !data.district) ||
                    (!data.cost || !data.landSquare)
                );
            case 4:
                return (
                    (!data.userName) ||
                    (!data.city || !data.district) ||
                    (!data.cost || !data.square || !data.repairs) ||
                    (!data.floor || !data.wallsMaterial || !data.builtYear) ||
                    (!data.info)
                );
            case 5:
                return (
                    (!data.userName) ||
                    (!data.city || !data.location || !data.street || !data.houseNumber) ||
                    (!data.cost)
                );
            case 6:
                return (
                    (!data.userName) ||
                    (!data.city || !data.district || !data.street) ||
                    (!data.cost) || (!data.cadastral) || (!data.landNumber)
                );
        }
    }

    save = (e) => {
        e.preventDefault();
        let data = Object.assign({}, this.state.data);
        let files = Object.assign({}, this.state.files);

        if (data.square !== '0.00' && Number(data.square) !== 0) {
            data.priceM2 = (data.cost / Number(data.square) || 1).toFixed(2);
        } else {
            data.priceM2 = data.cost;
        }
        data.isStudio = +data.isStudio;
        data.rooms = +data.rooms || 0;

        if (this.checkData(data.type)) {
            this.errorMsg('Все поля необходимы для заполнения!');
        }
        else if (!data.street) {
            this.errorMsg('Необходимо выбрать адрес из выпадающего списка!');
        }
        else if (!/^\+7[0-9]{10}$/.test(data.phoneNumber)) {
            this.errorMsg('Номер телефона необходимо указывать начиная с +7 и 10 цифр');
        }
        else if (!/^[0-9]{4}$/.test(data.builtYear)) {
            this.errorMsg('Год постройки необходимо указывать в формате хххх!');
        }
        else if (!/^[0-9]{1,6}$/.test(data.cost)) {
            this.errorMsg('Стоимость может содержать до 6 цифр!');
        }
        else if (this.props.onUserCreated) {
            this.props.onUserCreated({ data, files }, this.props.isEdit);
        }
        else if (data.info.length <= 400) {
            this.errorMsg('В поле "Описание обьекта" количество символов должно превышать 400! Сейчас:' + data.info.length);
        }

    }

    removeImage = (type, id) => {
        swal({ title: "Вы уверены?", icon: "warning", buttons: ["Нет", "Да"] })
            .then((ok) => {
                if (ok) {
                    Api.delete('/sellers/image/' + type + '/' + id)
                        .then(() => {
                            let data = this.state.data;

                            if (type === 'photo') {
                                let photo = data.photoArray.split(',');
                                photo = photo.filter(item => item !== id);

                                this.setState({
                                    data: {
                                        ...this.state.data,
                                        photoArray: photo.join(',')
                                    }
                                });
                            }
                            else if (type === 'layout') {
                                let layout = data.layoutArray.split(',');
                                layout = layout.filter(item => item !== id);

                                this.setState({
                                    data: {
                                        ...this.state.data,
                                        layoutArray: layout.join(',')
                                    }
                                });
                            }
                        })
                        .catch(() => { });
                }
            });
    }

    setData = async (name, value) => {
        const state = Object.assign({}, this.state);

        state.data[name] = value;
        state.addressSearchBox = {
            show: false,
            selectedId: 0
        }
        this.setState(state);
        if (name === 'address' && value !== this.addressLastValue) {
            setTimeout(() => { state.time_search_address = 1; this.setState(state); }, 2000)
            if (value && value.length > 0 && value !== this.addressLastValue && state.time_search_address == 1) {
                await Api.get('/geo/address?name=' + value)
                    .then(out => {
                        state.addressSearchBox = {
                            show: true,
                            data: out.value,
                            selectedId: 0
                        }
                    })
                    .catch(() => { });
                state.time_search_address = 0;
                this.setState(state);
            }
            else {
                state.addressSearchBox = {
                    show: false,
                    selectedId: 0
                }
                this.setState(state);
            }
            this.addressLastValue = value;
        }

    }

    setFile = (name, value) => {
        if (value && value.length !== 0) {
            const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
            const promises = [];
            for (let i = 0; i < value.length; i++) {
                promises.push(new Promise((resolve, reject) => {
                    if (allowedExtensions.test(value[i].name)) {
                        const fr = new FileReader;
                        fr.onload = function () {
                            const img = new Image;

                            img.onload = function () {

                                if (img.width > 1500 || img.height > 1500) {
                                    let width = 0, height = 0;
                                    if (img.width > 1500 && img.height < 1500) { width = 1500; height = (1500 / img.width) * img.height; }
                                    if (img.width < 1500 && img.height > 1500) { width = (1500 / img.height) * img.width; height = 1500; }
                                    if (img.width > 1500 && img.height > 1500) {
                                        if (img.width >= img.height) {
                                            width = 1500; height = (1500 / img.width) * img.height;
                                        } else {
                                            width = (1500 / img.height) * img.width; height = 1500;
                                        }
                                    }
                                    downscale(value[i], width, height, { returnBlob: 1 })
                                        .then(blob => {
                                            const file = new File([blob], value[i].name);
                                            resolve(file);
                                        })
                                } else {
                                    resolve(value[i]);
                                }
                            };

                            img.src = fr.result;
                        };
                        fr.readAsDataURL(value[i]);
                    } else {
                        resolve(value[i]);
                    }
                }))
            }
            Promise.all(promises).then(
                data => {
                    let state = Object.assign({}, this.state);
                    state.files[name] = data;
                    this.setState(state);
                }
            );
        }
    }

    validateData = (name, regexp, value, errorText) => {
        if (regexp.test(value)) {
            let state = Object.assign({}, this.state)

            state.data[name] = value;
            this.setState(state);
        }
        else {
            this.errorMsg(errorText);
        }
    }

    confirmSeller = (e) => {
        e.preventDefault();
        const data = this.state.data;

        Api.put('sellers/confirm/' + data.id)
            .then(() => {
                swal({ title: "Успешно", icon: "success", button: "Ок" });
                this.props.changePage('/sellers/moderation');
            })
            .catch(error => {
                this.errorMsg(error);
            });
    }

    revisionSeller = (e) => {
        e.preventDefault();
        const data = this.state.data;

        Api.put('/sellers/revision/' + data.id, { data: data.revisionComment })
            .then(() => {
                swal({ title: "Успешно", icon: "success", button: "Ок" });
                this.props.changePage('/sellers/moderation');
            })
            .catch(error => {
                this.errorMsg(error);
            });
    }

    render = () => {
        let data = this.state.data;
        const user = this.props.user;
        return (
            <>
                {data.revisionComment && !user.isAdmin && <div class="alert alert-danger">{data.revisionComment}</div>}

                <form className="as-row" onSubmit={this.save}>
                    {
                        <>
                            <div className="sidebar-left">

                                <div className="area-bg-gray">
                                    <h5>Основные параметры</h5>
                                    <MainParameters state={this.state} setData={this.setData} isEdit={this.props.isEdit} />
                                </div>

                                {(data.realtor === user.id || !this.props.isEdit) && (<div className="area-bg-gray">
                                    <h5>Данные клиента</h5>
                                    <ClientData state={this.state} setData={this.setData} />
                                </div>)}

                            </div>
                        </>
                    }

                    <div className="center">

                        {this.state.activeAd && <div className="seller-address">Адрес: {this.state.activeAd.address}</div>}

                        <div className="area-bg-gray">
                            <h5>Местоположение</h5>
                            <LocationCreatePage state={this.state} setData={this.setData} isEdit={this.props.isEdit} />
                        </div>

                        <div className="area-bg-gray">
                            <h5>Параметры объекта</h5>
                            <div className="input-group">
                                <ParameterCreatePage state={this.state} setData={this.setData} validateData={this.validateData} />
                            </div>
                        </div>


                        <div className="area-bg-gray">
                            <h5>Здание</h5>
                            <div className="input-group">
                                <BuildingCreatePage state={this.state} setData={this.setData} validateData={this.validateData} />
                            </div>
                        </div>


                        <div className="input-group">
                            <div>
                                <label>
                                    <div className="title">Планировка</div>
                                    <div className="value">
                                        <input type="file" multiple onChange={(e) => this.setFile('layout', e.target.files)} />
                                    </div>
                                </label>
                                <div className="photoPrev">
                                    {typeof data.layoutArray === 'string' && data.layoutArray.length > 0 &&
                                        data.layoutArray.split(',').map(item =>
                                            <div style={{ backgroundImage: 'url(http://' + Api.getAPIUrl() + '/static/layout/' + data.id + '_' + item + '.png)' }}>
                                                <div className="close" onClick={() => this.removeImage('layout', item)}>x</div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            <div>
                                <label>
                                    <div className="title">Фото</div>
                                    <div className="value">
                                        <input type="file" className="custom-file-input custom-file-input-sm" multiple onChange={(e) => this.setFile('photo', e.target.files)} />
                                    </div>
                                </label>
                                <div className="photoPrev">
                                    {typeof data.photoArray === 'string' && data.photoArray.length > 0 &&
                                        data.photoArray.split(',').map(item =>
                                            <div style={{ backgroundImage: 'url(http://' + Api.getAPIUrl() + '/static/photo/' + data.id + '_' + item + '.png)' }}>
                                                <div className="close" onClick={() => this.removeImage('photo', item)}>x</div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="input-group">
                            <label>
                                <div className="title required">Описание объекта</div>
                                <div className="value">
                                    <textarea className="form-control form-control-sm" cols="70" onChange={(e) => this.setData('info', e.target.value)} value={data.info} />
                                </div>
                            </label>
                            <label>
                                <div className="title">Комментарий для риэлтора</div>
                                <div className="value">
                                    <textarea className="form-control form-control-sm" onChange={(e) => this.setData('realtorComment', e.target.value)} value={data.revisionComment} />
                                </div>
                            </label>
                        </div>





                        <div className="input-group btn-group">
                            <>
                                {(data.realtor === user.id || !!user.isAdmin || !this.props.isEdit) && <button className="btn btn-sm btn-info">Сохранить</button>}
                                <Link to=".." className="btn btn-sm btn-danger">Отменить</Link>
                            </>
                            {/*{*/}
                            {/*    (this.props.isEdit && user.isAdmin)*/}
                            {/*    ?*/}
                            {/*        <>*/}
                            {/*            /!*<button className="btn btn-sm btn-success" onClick={this.confirmSeller}>Подтвердить</button>*!/*/}
                            {/*            /!*<button className="btn btn-sm btn-warning" onClick={this.revisionSeller}>На доработку</button>*!/*/}
                            {/*            <Link to=".." className="btn btn-sm btn-danger">Отменить</Link>*/}
                            {/*        </>*/}
                            {/*    :*/}
                            {/*        null*/}
                            {/*}*/}
                        </div>
                    </div>

                    {user.seller_moderation === 1 ? (user.id === data.realtor ? (
                        (
                            <div className="sidebar-right">
                                <h5>Администратору</h5>
                                <div className="input-group">
                                    <Administrator state={this.state} setData={this.setData} />
                                </div>
                            </div>
                        )
                    ) : '') : (
                        user.seller_moderation === 2 ? (user.agencyId === data.realtorAgencyId ? (
                            <div className="sidebar-right">
                                <h5>Администратору</h5>
                                <div className="input-group">
                                    <Administrator state={this.state} setData={this.setData} />
                                </div>
                            </div>
                        ) : '') : (
                            user.seller_moderation === 3 ? (
                                <div className="sidebar-right">
                                    <h5>Администратору</h5>
                                    <div className="input-group">
                                        <Administrator state={this.state} setData={this.setData} />
                                    </div>
                                </div>
                            ) : ''
                        )
                    )}

                </form>


            </>
        )
    }
}

const mapStateToProps = ({ user, general, seller, router }) => ({
    user,
    general,
    seller,
    location: router.location
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            changePage: (page) => push(page)
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SellersCreatePage)
